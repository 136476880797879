export const GENERAL = 'general'
export const CALLS = 'calls'
export const SHOWS = 'shows'
export const OBJECTS = 'objects'

export const TOP_AGENTS = 'topAgents'
export const POPULAR_OBJECTS = 'popularObjects'
export const UNPOPULAR_OBJECTS = 'unpopularObjects'

export const ACTIVE_TAB_ITEMS = {
  [GENERAL]: 'Общая',
  [CALLS]: 'Лиды',
  [SHOWS]: 'Показы',
  [OBJECTS]: 'Объекты'
}
