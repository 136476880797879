import Vue from 'vue'

export const alerts = Vue.observable({
  list: []
})

export const alertService = {
  list: alerts.list,

  add({ type, text }) {
    this.list.push({ type, text })
  },

  shift() {
    this.list.shift()
  }
}
