<template>
  <a
    v-if="!disabled && href"
    class="button"
    :class="classes"
    :href="href"
    v-bind="$attrs"
    target="_blank"
    v-on="$listeners"
  >
    <slot></slot>
  </a>
  <router-link v-else-if="!disabled && to" class="button" :class="classes" :to="to" v-on="$listeners">
    <slot></slot>
  </router-link>
  <button v-else :disabled="disabled" class="button" type="button" v-bind="$attrs" :class="classes" v-on="$listeners">
    <slot></slot>
  </button>
</template>

<script>
export default {
  name: 'VButton',
  props: {
    rounded: { type: Boolean, default: false },
    primary: { type: Boolean, default: false },
    isText: { type: Boolean, default: false },
    to: { type: [String, Object], default: null },
    href: { type: String, default: null },
    disabled: { type: Boolean, default: false },
    compressed: { type: Boolean, default: false }
  },
  computed: {
    classes() {
      return {
        'button--primary': this.primary,
        'button--rounded': this.rounded,
        'button--text': this.isText,
        'button--disabled': this.disabled,
        'button--compressed': this.compressed
      }
    }
  }
}
</script>
