import { getFormDataFrom } from '@/utils/common'
import BaseHttpService from './base'

class UploadingService extends BaseHttpService {
  uploadImage(image, progressCb) {
    const formData = getFormDataFrom({ image })
    return this.client.post('/background/media/', formData, { onUploadProgress: progressCb })
  }

  uploadFile(file, progressCb) {
    const formData = getFormDataFrom({ file })
    return this.client.post('/background/files/', formData, { onUploadProgress: progressCb })
  }
}

export default UploadingService
