import { deleteEverythingExceptNumbers, formatAdvertObject } from '@/utils/formatters'
import { getChangedCategoriesForAdverts, getStringCapitalize, pickProperties, toSnakeCaseObject } from '@/utils/common'
import {
  REALTY_DRAFT_STATUS_ACCEPTED,
  REALTY_DRAFT_STATUS_DRAFT,
  REALTY_DRAFT_STATUS_REJECTED,
  REALTY_STATUS_ACTIVE,
  REALTY_STATUS_DRAFT
} from '@/constants/statuses/realty'
import { STATUS_SOLD } from '@/constants/objectRealtyOptions'
import { analyticsEvents } from '@/services/segment'
import BaseHttpService from './base'

function formatSearch(query) {
  return /\d/.test(query[0]) ? deleteEverythingExceptNumbers(query) : query
}

function formatList(list) {
  const results = list.results.map(el => {
    const { status, ...otherFields } = pickProperties(el, [
      'id',
      'name',
      'url',
      'cianUrl',
      'ownerProfileAccount',
      'yandexUrl',
      'price',
      'agent',
      'advert',
      'photo',
      'status',
      'showsCount',
      'callsCount',
      'viewsCount',
      'hasNotification',
      'plottage',
      'area',
      'domClickUrl'
    ])

    return { ...otherFields, advertStatus: status }
  })
  return {
    ...list,
    results
  }
}
function setSoldoutAt({ soldoutAt, status }) {
  let localSoldoutAt = soldoutAt
  if (!localSoldoutAt) {
    if (status === STATUS_SOLD) {
      localSoldoutAt = undefined
    }
  }
  return localSoldoutAt
}

class AdvertsService extends BaseHttpService {
  select(search) {
    return this.client
      .get('/real-estate/select/', { params: { search } })
      .then(res => res.map(el => formatAdvertObject(el)))
  }

  selectCategory() {
    return this.client.get('/real-estate/categories/')
  }

  getList({
    limit,
    offset,
    orderBy,
    agentId,
    query,
    categoryId,
    dateTo,
    dateFrom,
    status,
    priceLte,
    priceGte,
    facingType,
    dealType,
    advertStatus
  }) {
    const isPublished = status === REALTY_STATUS_ACTIVE
    const params = toSnakeCaseObject({
      limit,
      offset,
      agent: agentId,
      name_or_id: query,
      category: categoryId,
      dateTo,
      dateFrom,
      priceLte,
      priceGte,
      isPublished,
      orderBy,
      facingType,
      dealType,
      status: advertStatus
    })
    if (status === REALTY_STATUS_DRAFT) {
      return this.client
        .get('/real-estate/drafts/', { params: { ...params, is_published: undefined } })
        .then(response => formatList(response))
    }
    return this.client
      .get('/real-estate/', {
        params
      })
      .then(response => formatList(response))
  }

  getById({ id, status }) {
    if (status === REALTY_STATUS_DRAFT) return this.client.get(`/real-estate/drafts/${id}/`)
    return this.client.get(`/real-estate/${id}/`)
  }

  createDraft(params, isWithNotice, isAdmin) {
    return this.client
      .post('/real-estate/drafts/new_draft/', {
        ...params,
        agent: params.agent?.id,
        soldoutAt: setSoldoutAt(params),
        isPublishStatus: isWithNotice,
        price: params?.price || null,
        feedDealType: params.feedDealType || undefined
      })
      .then(result => {
        if (isWithNotice) {
          if (isAdmin)
            analyticsEvents.propertyAdded({
              propertyCategory: getStringCapitalize(result.category?.slug),
              addedSuccessfully: true,
              propertyDraftSaved: false
            })
          else {
            analyticsEvents.draftPropertyAdded({
              propertyCategory: getStringCapitalize(result.category?.slug)
            })
            analyticsEvents.draftPropertySentForApproval({
              propertyCategory: getStringCapitalize(result.category?.slug)
            })
          }
        } else {
          analyticsEvents.draftPropertyAdded({
            propertyCategory: getStringCapitalize(result.category?.slug)
          })
        }
        return result
      })
  }

  createDraftBasedOnRealty(realtyId, params, isWithNotice, isAdmin) {
    return this.client
      .post('/real-estate/drafts/', {
        ...params,
        advert: realtyId,
        soldoutAt: setSoldoutAt(params),
        agent: params.agent?.id,
        isPublishStatus: isWithNotice
      })
      .then(result => {
        if (isWithNotice) {
          if (isAdmin) {
            analyticsEvents.propertyChanged(getChangedCategoriesForAdverts(result.changes))
          } else {
            analyticsEvents.draftPropertyAdded({
              propertyCategory: getStringCapitalize(result.category?.slug)
            })
            analyticsEvents.draftPropertySentForApproval({
              propertyCategory: getStringCapitalize(result.category?.slug)
            })
          }
        } else {
          analyticsEvents.draftPropertyAdded({
            propertyCategory: getStringCapitalize(result.category?.slug)
          })
        }
        return result
      })
  }

  updateDraft(id, params, isWithNotice, isAdmin) {
    return this.client
      .put(`/real-estate/drafts/${id}/`, {
        ...params,
        agent: params.agent?.id,
        soldoutAt: setSoldoutAt(params),
        isPublishStatus: isWithNotice,
        price: params?.price || null
      })
      .then(result => {
        if (isWithNotice) {
          if (isAdmin) {
            // TODO: взять от бэка нормальный флаг
            if (result.revisionStatus === REALTY_DRAFT_STATUS_DRAFT) {
              analyticsEvents.propertyAdded({
                propertyCategory: getStringCapitalize(result.category?.slug),
                addedSuccessfully: true,
                propertyDraftSaved: false
              })
            } else {
              analyticsEvents.propertyChanged(getChangedCategoriesForAdverts(result.changes))
            }
          } else {
            analyticsEvents.draftPropertySentForApproval({
              propertyCategory: getStringCapitalize(result.category?.slug)
            })
          }
        } else {
          analyticsEvents.draftPropertyChanged({
            propertyCategory: getStringCapitalize(result.category?.slug)
          })
        }
        return result
      })
  }

  updateStatus(id, isPublished) {
    return this.client.put(`/real-estate/publish/${id}/`, { isPublished })
  }

  approveDraft(id, { draftStatus, initialNotificationId }) {
    return this.client.put(`/real-estate/approve-drafts/${id}/`, {
      draftStatus,
      initialNotificationId
    })
  }

  rejectDraft(id, initialNotificationId) {
    return this.client.put(`/real-estate/approve-drafts/${id}/`, {
      draftStatus: REALTY_DRAFT_STATUS_REJECTED,
      initialNotificationId
    })
  }

  acceptDraft(id, initialNotificationId) {
    return this.client.put(`/real-estate/approve-drafts/${id}/`, {
      draftStatus: REALTY_DRAFT_STATUS_ACCEPTED,
      initialNotificationId
    })
  }

  deleteDraft(id) {
    return this.client.delete(`/real-estate/drafts/${id}/`)
  }

  selectTags({ advertType, dealType }) {
    const params = { advertType, dealType }
    return this.client.get('/real-estate/tags/', { params: toSnakeCaseObject(params) })
  }

  fetchCategories() {
    return this.client.get('/real-estate/categories/')
  }

  getAdvert(id) {
    return this.client.get(`/real-estate/${id}/`)
  }

  getSoldAdverts() {
    return this.client.get(`/real-estate/sold/`)
  }

  getMainAnalyticsInfo(id) {
    return this.client.get(`/real-estate/${id}/main-info/`)
  }

  // price statistic
  getAdvertPriceSummary(id) {
    return this.client.get(`/real-estate/${id}/analytics/price/summary-information/`)
  }

  getAdvertPriceSimilar(id) {
    return this.client.get(`/real-estate/${id}/analytics/price/get-similar-real-estate/`)
  }

  getAdvertPriceChange(id, params) {
    return this.client.get(`/real-estate/${id}/analytics/price/change-graph/`, { params: toSnakeCaseObject(params) })
  }

  // calls statistic
  getAdvertCallsSummary(id) {
    return this.client.get(`/real-estate/${id}/analytics/calls/summary-information/`)
  }

  getAdvertCallsChange(id, params) {
    return this.client.get(`/real-estate/${id}/analytics/calls/change-graph/`, { params: toSnakeCaseObject(params) })
  }

  getAdvertCallsBarGraph(id) {
    return this.client.get(`/real-estate/${id}/analytics/calls/bar-graph/`)
  }

  getAdvertCallsList(id, { search, ...otherParams }) {
    const params = {
      search: search ? formatSearch(search) : undefined,
      ...otherParams
    }
    return this.client.get(`/real-estate/${id}/analytics/calls/list/`, {
      params: toSnakeCaseObject(params)
    })
  }

  getAdvertShowsSummary(id) {
    return this.client.get(`/real-estate/${id}/analytics/shows/summary-information/`)
  }

  getAdvertShowsChange(id, params) {
    return this.client.get(`/real-estate/${id}/analytics/shows/change-graph/`, { params: toSnakeCaseObject(params) })
  }

  getAdvertShowsBarGraph(id) {
    return this.client.get(`/real-estate/${id}/analytics/shows/bar-graph/`)
  }

  getAdvertShowsList(id, { search, ...otherParams }) {
    const params = {
      search: search ? formatSearch(search) : undefined,
      ...otherParams
    }

    return this.client.get(`/real-estate/${id}/analytics/shows/list/`, { params: toSnakeCaseObject(params) })
  }
}

export default AdvertsService
