<template>
  <v-dialog :title="title" v-bind="$attrs" v-on="$listeners">
    <component
      :is="customComponent.component"
      v-if="customComponent"
      class="confirmation__custom-component"
      v-bind="customComponent.attrs"
    ></component>
    <div class="confirmation__actions">
      <v-button class="confirmation__action" @click="cancel">{{ cancelText }}</v-button>
      <v-button class="confirmation__action" primary @click="confirm">{{ confirmText }}</v-button>
    </div>
  </v-dialog>
</template>

<script>
import VDialog from '@/components/common/VDialog.vue'
import VButton from '@/components/common/VButton.vue'

export default {
  name: 'VConfirmation',
  components: { VDialog, VButton },
  inheritAttrs: false,
  props: {
    customComponent: {
      type: Object,
      default: () => {
        return {}
      }
    },
    title: { type: String, required: true },
    confirmText: { type: String, default: 'Подтвердить' },
    cancelText: { type: String, default: 'Отмена' }
  },
  methods: {
    confirm() {
      this.$emit('confirm')
    },
    cancel() {
      this.$emit('cancel')
    }
  }
}
</script>
