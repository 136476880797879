export const PHONE_MASK = [
  '8',
  ' ',
  '(',
  /[1-9]/,
  /\d/,
  /\d/,
  ')',
  ' ',
  /\d/,
  /\d/,
  /\d/,
  '-',
  /\d/,
  /\d/,
  '-',
  /\d/,
  /\d/
]

export const CADASTRAL_NUMBER_MASK = [
  /\d/,
  /\d/,
  ':',
  /\d/,
  /\d/,
  ':',
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  ':',
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  /\d/
]

export const TEXT_MASK_CONFIG_DEFAULT = {
  guide: false,
  placeholderChar: '#'
}

export const TEXT_MASK_AVAILABLE_INPUT_TYPES = ['text', 'tel', 'url', 'password', 'search']
