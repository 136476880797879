import { ownersService, customersService } from '@/services/http'

export const MAP_DYNAMIC_TITLE_FETCH_PARAMS = {
  'owner-edit': {
    service: ownersService,
    action: 'getOwnerById',
    routeParam: 'ownerId'
  },
  'customer-edit': {
    service: customersService,
    action: 'getCustomerById',
    routeParam: 'customerId'
  }
}
