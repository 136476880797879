import config from '@/config'
import { setup } from 'axios-cache-adapter'

const timeout = 600000
const client = setup({
  baseURL: config.baseURL,
  timeout,
  cache: {
    maxAge: 0
  }
})

export function setHeader(key, value) {
  client.defaults.headers.common[key] = value
}

export function unsetHeader(key) {
  delete client.defaults.headers.common[key]
}

export default client
