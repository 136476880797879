import { toSnakeCaseObject } from '@/utils/common'
import { analyticsEvents } from '@/services/segment'
import { OUTGOING_CALL } from '@/constants/ownerInteractionHistory'
import BaseHttpService from './base'

class RemindersService extends BaseHttpService {
  getReminderCalendarDates(params) {
    return this.client.get('/reminders/calendar/', { params: toSnakeCaseObject(params) })
  }

  getReminders({ isExpired, range }) {
    const params = toSnakeCaseObject({
      isExpired,
      dateFrom: range?.[0] || undefined,
      dateTo: range?.[1] || undefined
    })
    return this.client.get('/reminders/', { params })
  }

  setEventAsCompleted({ isCompleted, id, eventType }) {
    return this.client.patch(`/reminders/${id}/`, { isCompleted }).then(result => {
      analyticsEvents.reminderChanged({
        reminderType: eventType === OUTGOING_CALL ? 'Out Call' : 'Tour',
        reminderStatus: isCompleted ? 'Done' : 'Not Done',
        timeChanged: false,
        dateChanged: false,
        commentChanged: false
      })
      return result
    })
  }

  getExpiredCount() {
    return this.client.get('/reminders/expired-count/')
  }
}

export default RemindersService
