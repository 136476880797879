<template>
  <v-dialog v-bind="$attrs" v-on="$listeners">
    <div class="v-notification">
      <div class="v-notification__icon">
        <v-icon-success v-if="type === 'success'" />
      </div>
      <div class="v-notification__text">{{ text }}</div>
    </div>
  </v-dialog>
</template>

<script>
import VDialog from '@/components/common/VDialog.vue'
import VIconSuccess from '@/components/icons/VSuccess.vue'

const NOTIFICATION_TYPES = ['success', 'error', 'info']

export default {
  name: 'VNotification',
  components: { VDialog, VIconSuccess },
  inheritAttrs: false,
  props: {
    type: {
      type: String,
      default: 'info',
      validate: val => NOTIFICATION_TYPES.includes(val)
    },
    text: { type: String, required: true }
  }
}
</script>
