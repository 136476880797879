import base from './base'
import dev from './dev'
import prod from './prod'

const devEnv = process.env.NODE_ENV === 'development'

export default {
  ...base,
  ...(devEnv ? dev : prod)
}
