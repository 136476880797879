<template>
  <mounting-portal append mount-to="#dialog-root">
    <fade-transition>
      <div v-if="visible" class="dialog-overlay" @click="closeOnOverlayClick && close()"></div>
    </fade-transition>

    <slide-transition>
      <div
        v-if="visible"
        :class="`dialog theme-${isNmh ? 'nmh' : 'agentik'}`"
        role="dialog"
        @click="closeOnOverlayClick && close()"
      >
        <div class="dialog__document-wrapper">
          <div :class="['dialog__document', `dialog__document--${size}`]" role="document" @click.stop>
            <button v-if="!hideCloseButton" class="dialog__close" type="button" aria-label="Close" @click="close">
              <v-icon-cross />
            </button>
            <slot name="header">
              <h4 v-if="title" class="dialog__title">{{ title }}</h4>
            </slot>
            <div v-if="!$slots.body" v-scroll-lock="visible" class="dialog__content-wrapper">
              <div class="dialog__content">
                <slot />
              </div>
            </div>
            <div v-else v-scroll-lock="visible" class="dialog__content-wrapper">
              <slot name="body" />
            </div>
          </div>
        </div>
      </div>
    </slide-transition>
  </mounting-portal>
</template>

<script>
import VIconCross from '@/components/icons/VCross.vue'
import FadeTransition from '@/components/transitions/FadeTransition.vue'
import SlideTransition from '@/components/transitions/SlideTransition.vue'
import { MountingPortal } from 'portal-vue'
import ScrollLock from '@/directives/body-scroll-lock'
import { mapGetters } from 'vuex'
import { MODULE_SESSION } from '@/store/modules/session/session.types'

export default {
  name: 'VDialog',
  components: { MountingPortal, SlideTransition, FadeTransition, VIconCross },
  directives: {
    ScrollLock
  },
  props: {
    closeOnOverlayClick: { type: Boolean, default: false },
    visible: { type: Boolean, required: true },
    size: {
      type: String,
      default: 'normal',
      //!  extra-large - 880px, large-plus - 670px, large - 496px, normal - 456px , small - 363px, tiny - 345px
      validator: size => ['normal', 'small', 'large', 'large-plus', 'extra-large', 'tiny'].includes(size)
    },
    title: { type: String, default: '' },
    hideCloseButton: { type: Boolean, default: false }
  },
  computed: {
    ...mapGetters({
      isNmh: `${MODULE_SESSION}/isNmh`
    })
  },
  methods: {
    close() {
      this.$emit('update:visible', false)
    }
  }
}
</script>
