import { MODULE_SESSION } from '@/store/modules/session/session.types'
import { ENTRY_ROUTE_NAME_FOR_REDIRECT } from '@/constants/routes'

export default function checkRole(roles) {
  return ({ store, next }) => {
    const { role } = store.state[MODULE_SESSION].model
    if (roles.includes(role)) {
      return next()
    }
    return next({ name: ENTRY_ROUTE_NAME_FOR_REDIRECT })
  }
}
