<template>
  <v-dialog size="small" v-bind="$attrs" v-on="$listeners">
    <div class="error-dialog__wrapper">
      <div class="error-dialog__img-wrapper">
        <img
          class="error-dialog__img"
          :src="`/images/${illustrationImageName}.png`"
          :srcset="`/images/${illustrationImageName}@2x.png 2x, /images/${illustrationImageName}@3x.png 3x`"
          alt=""
        />
      </div>

      <h3 class="error-dialog__title">{{ title }}</h3>

      <h4 class="error-dialog__list-title">{{ listTitle }}</h4>
      <ol class="error-dialog__fields">
        <li v-for="(errorField, index) in errorFields" :key="index" class="error-dialog__field">
          {{ errorField }}
        </li>
      </ol>

      <v-button primary class="error-dialog__button" @click="close">{{ buttonText }}</v-button>
    </div>
  </v-dialog>
</template>

<script>
import VDialog from '@/components/common/VDialog.vue'
import VButton from '@/components/common/VButton.vue'
import { mapGetters } from 'vuex'
import { MODULE_SESSION } from '@/store/modules/session/session.types'

export default {
  name: 'ErrorDialog',
  components: { VDialog, VButton },
  inheritAttrs: false,
  props: {
    title: { type: String, default: '' },
    listTitle: { type: String, default: '' },
    buttonText: { type: String, default: 'Вернуться к редактированию' },
    errorFields: { type: Array, default: () => [] }
  },
  computed: {
    ...mapGetters({
      isNmh: `${MODULE_SESSION}/isNmh`
    }),
    illustrationImageName() {
      return this.isNmh ? 'illustration.mini' : 'blue-illustration.mini'
    }
  },
  methods: {
    close() {
      this.$emit('close')
    }
  }
}
</script>

<style lang="scss">
ol {
  margin: 0;
  padding: 0;
}

.error-dialog {
  &__title {
    font-size: 18px;
    margin-bottom: 25px;
  }

  &__img-wrapper {
    text-align: center;
  }

  &__img {
    margin-bottom: 27px;
  }

  &__list-title {
    font-weight: normal;
    font-size: $--font-size-medium;
    margin-bottom: 2px;
  }

  &__fields {
    list-style-type: none;
    counter-reset: num;
    margin-bottom: 22px;
  }

  &__field {
    &::before {
      content: counter(num) '.';
      counter-increment: num;
    }
  }

  &__button {
    width: 100%;
  }
}
</style>
