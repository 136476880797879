import * as moduleTypes from '@/store/modules/session/session.types'
import { LOGOUT } from '@/store/common/actions.types'
import { SET_MODEL, LOADED, LOADING, SET_ERROR } from '@/store/common/mutations.types'
import throwError from '@/utils/store'
import { authService } from '@/services/http'
import { pickCommonMutations } from '@/store/common/mutations'
import { ROLE_ADMIN, ROLE_AGENT, ROLE_OWNER } from '@/constants/roles'
import { getSubdomain } from '@/utils/http'

function generateModel() {
  return {
    id: null,
    name: null,
    email: null,
    photo: null,
    role: null
  }
}
function generateTenantModel() {
  return {
    id: null,
    organizationName: null,
    status: null,
    organizationUsersCount: null,
    subscription: null
  }
}

const necessaryMutations = [SET_MODEL, LOADING, LOADED, SET_ERROR]
const mutations = pickCommonMutations(necessaryMutations)

export default {
  namespaced: true,

  state: {
    error: {
      code: null,
      detail: null
    },
    accessToken: authService.tokens.access,
    loading: false,
    subdomain: getSubdomain(),
    model: generateModel(),
    tenantModel: generateTenantModel()
  },

  mutations: {
    ...mutations,

    [moduleTypes.SET_TENANT_MODEL]: (state, { model }) => {
      state.tenantModel = model
    },
    [moduleTypes.CHANGE_ACCESS_TOKEN]: (state, token) => {
      state.accessToken = token
    }
  },

  getters: {
    isNmh: () => {
      return process.env.VUE_APP_IS_NMH === 'true'
    },
    tenantOrganizationName: state => {
      return state.tenantModel.organizationName
    },
    isProfileLoaded: state => {
      return !!state.model.id
    },
    isTenantInfoLoaded: state => {
      return !!state.tenantModel.id
    },
    isRoleAdmin: state => {
      return state.model.role === ROLE_ADMIN
    },
    isRoleOwner: state => {
      return state.model.role === ROLE_OWNER
    },
    isRoleAgent: state => {
      return state.model.role === ROLE_AGENT
    }
  },

  actions: {
    [moduleTypes.FETCH_USER_INFO]({ commit }) {
      commit(LOADING)

      return authService
        .fetchUserInfo()
        .then(model => {
          commit(SET_MODEL, { model })
        })
        .catch(throwError(commit, 'Ошибка получения информации о пользователе'))
        .finally(() => commit(LOADED))
    },

    [moduleTypes.FETCH_TENANT_INFO]({ commit }) {
      commit(LOADING)

      return authService
        .fetchTenantInfo()
        .then(model => {
          commit(moduleTypes.SET_TENANT_MODEL, { model })
        })
        .catch(throwError(commit, 'Ошибка получения информации об арендаторе'))
        .finally(() => commit(LOADED))
    },

    [moduleTypes.SET_ACCESS_TOKEN]({ commit }, token) {
      commit(moduleTypes.CHANGE_ACCESS_TOKEN, token)
    },

    [LOGOUT]({ commit }) {
      authService.removeAuthTokens()
      const model = generateModel()
      commit(SET_MODEL, { model })
    }
  }
}
