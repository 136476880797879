import { ERROR_CODE_UNKNOWN } from '@/constants/http'
import { toCamelCaseObject } from './common'

export function getSubdomain() {
  const url = new URL(window.location.href)
  const urlChunks = url.host.split('.')
  const urlLength = urlChunks.length
  const subdomainChunks = urlChunks.slice(0, urlLength - 2)

  return subdomainChunks.length ? subdomainChunks.join('.') : null
}

export function getDetailedError(error) {
  const { status } = error.response
  const { code } = error.response.data.error || {}

  return toCamelCaseObject({
    status,
    code: Number(code) || ERROR_CODE_UNKNOWN,
    details: error.response.data
  })
}

export default getDetailedError
