<template>
  <div class="v-confirmation-group">
    <v-confirmation
      :title="title"
      :cancel-text="cancelText"
      :confirm-text="confirmText"
      :visible="!!confirmation"
      :custom-component="customComponent"
      @confirm="confirm"
      @cancel="cancel"
      @update:visible="cancel"
    >
    </v-confirmation>
  </div>
</template>

<script>
import { confirmationService, confirmations } from '@/services/confirmation'
import VConfirmation from './VConfirmation.vue'

export default {
  name: 'VConfirmationGroup',
  components: { VConfirmation },

  computed: {
    confirmation() {
      return confirmations.list[0]
    },
    customComponent() {
      return this.confirmation?.customComponent
    },
    title() {
      return this.confirmation?.title || ''
    },
    cancelText() {
      return this.confirmation?.cancelText
    },
    confirmText() {
      return this.confirmation?.confirmText
    }
  },
  methods: {
    confirm() {
      this.confirmation.confirm()
      confirmationService.shift()
    },
    cancel() {
      this.confirmation.cancel()
      confirmationService.shift()
    }
  }
}
</script>
