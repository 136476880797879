import { toSnakeCaseObject } from '@/utils/common'
import BaseHttpService from './base'

class ArticlesService extends BaseHttpService {
  getList({ limit, offset, search, year, categoryId, status }) {
    const params = toSnakeCaseObject({
      limit,
      offset,
      status,
      title: search,
      year,
      category: categoryId
    })
    return this.client.get('/articles/', { params })
  }

  create(article) {
    const previewImage = article.previewImage?.id || null

    return this.client.post('/articles/', { ...article, previewImage })
  }

  getById(id) {
    return this.client.get(`/articles/${id}/`)
  }

  update(id, article) {
    return this.client.patch(`/articles/${id}/`, article)
  }

  delete(id) {
    return this.client.delete(`/articles/${id}/`)
  }

  updateStatus(id, status) {
    return this.client.patch(`/articles/${id}/`, { status })
  }

  selectYears() {
    return this.client.get('/articles/years-select/')
  }

  selectCategory() {
    return this.client.get('/articles/categories/select/')
  }

  selectAuthors() {
    return this.client.get('/articles/authors-select/')
  }
}

export default ArticlesService
