// fork of the body-scroll-lock library - https://github.com/willmcpo/body-scroll-lock

let hasPassiveEvents = false
if (typeof window !== 'undefined') {
  const passiveTestOptions = {
    get passive() {
      hasPassiveEvents = true
      return undefined
    }
  }
  window.addEventListener('testPassive', null, passiveTestOptions)
  window.removeEventListener('testPassive', null, passiveTestOptions)
}

const isIosDevice =
  typeof window !== 'undefined' &&
  window.navigator &&
  window.navigator.platform &&
  (/iP(ad|hone|od)/.test(window.navigator.platform) ||
    (window.navigator.platform === 'MacIntel' && window.navigator.maxTouchPoints > 1))

let locks = []
let documentListenerAdded = false
let initialClient = { x: -1, y: -1 }
let previousBodyOverflowSetting
let previousBodyPaddingRight

let axis = null

// returns true if `el` should be allowed to receive touchmove events.
const allowTouchMove = el =>
  locks.some(lock => {
    return lock.options.allowTouchMove && lock.options.allowTouchMove(el)
  })

const preventDefault = rawEvent => {
  const e = rawEvent || window.event

  // For the case whereby consumers adds a touchmove event listener to document.
  // Recall that we do document.addEventListener('touchmove', preventDefault, { passive: false })
  // in disableBodyScroll - so if we provide this opportunity to allowTouchMove, then
  // the touchmove event on document will break.
  if (allowTouchMove(e.target)) {
    return true
  }

  // Do not prevent if the event has more than one touch (usually meaning this is
  // a multi touch gesture like pinch to zoom).
  if (e.touches.length > 1) return true

  if (e.preventDefault) e.preventDefault()

  return false
}

const setOverflowHidden = options => {
  // If previousBodyPaddingRight is already set, don't set it again.
  if (previousBodyPaddingRight === undefined) {
    const reserveScrollBarGap = !!options && options.reserveScrollBarGap === true
    const scrollBarGap = window.innerWidth - document.documentElement.clientWidth

    if (reserveScrollBarGap && scrollBarGap > 0) {
      const computedBodyPaddingRight = parseInt(getComputedStyle(document.body).getPropertyValue('padding-right'), 10)
      previousBodyPaddingRight = document.body.style.paddingRight
      document.body.style.paddingRight = `${computedBodyPaddingRight + scrollBarGap}px`
    }
  }

  // If previousBodyOverflowSetting is already set, don't set it again.
  if (previousBodyOverflowSetting === undefined) {
    previousBodyOverflowSetting = document.body.style.overflow
    document.body.style.overflow = 'hidden'
  }
}

const restoreOverflowSetting = () => {
  if (previousBodyPaddingRight !== undefined) {
    document.body.style.paddingRight = previousBodyPaddingRight

    // Restore previousBodyPaddingRight to undefined so setOverflowHidden knows it
    // can be set again.
    previousBodyPaddingRight = undefined
  }

  if (previousBodyOverflowSetting !== undefined) {
    document.body.style.overflow = previousBodyOverflowSetting

    // Restore previousBodyOverflowSetting to undefined
    // so setOverflowHidden knows it can be set again.
    previousBodyOverflowSetting = undefined
  }
}

// https://developer.mozilla.org/en-US/docs/Web/API/Element/scrollHeight#Problems_and_solutions
const isTargetElementTotallyScrolled = (targetElement, totalAxis) => {
  if (targetElement) {
    const totalScroll = targetElement[`scroll${totalAxis === 'y' ? 'Height' : 'Width'}`]
    const scrolled = targetElement[`scroll${totalAxis === 'y' ? 'Top' : 'Left'}`]
    const clientSize = targetElement[`client${totalAxis === 'y' ? 'Height' : 'Width'}`]
    return totalScroll - scrolled <= clientSize
  }
  return false
}

const handleScroll = (event, targetElement, totalAxis) => {
  const touch = event.targetTouches[0]
  const initialPos = initialClient[axis]
  const scrollPos = targetElement && targetElement[`scroll${totalAxis === 'y' ? 'Top' : 'Left'}`]
  const clientPos = (axis === 'y' ? touch.clientY : touch.clientX) - initialPos

  if (allowTouchMove(event.target)) {
    return false
  }

  if (targetElement && scrollPos === 0 && clientPos > 0) {
    // element is at the top of its scroll.
    return preventDefault(event)
  }

  if (isTargetElementTotallyScrolled(targetElement, axis) && clientPos < 0) {
    // element is at the bottom of its scroll.
    return preventDefault(event)
  }

  event.stopPropagation()
  return true
}

export const disableBodyScroll = (targetElement, options) => {
  // targetElement must be provided
  if (!targetElement) {
    throw new Error(
      'disableBodyScroll unsuccessful - targetElement must be provided when calling disableBodyScroll on IOS devices.'
    )
  }

  // disableBodyScroll must not have been called on this targetElement before
  if (locks.some(lock => lock.targetElement === targetElement)) {
    return
  }

  const lock = {
    targetElement,
    options: options || {}
  }

  locks = [...locks, lock]

  if (isIosDevice) {
    // eslint-disable-next-line no-param-reassign
    targetElement.ontouchstart = event => {
      if (event.targetTouches.length === 1) {
        // detect single touch.
        initialClient = {
          x: event.targetTouches[0].clientX,
          y: event.targetTouches[0].clientY
        }
        axis = null
      }
    }
    // eslint-disable-next-line no-param-reassign
    targetElement.ontouchmove = event => {
      if (event.targetTouches.length === 1) {
        // detect single touch.
        if (!axis) {
          const distX = Math.abs(initialClient.x - event.targetTouches[0].clientX)
          const distY = Math.abs(initialClient.y - event.targetTouches[0].clientY)
          axis = distX > distY ? 'x' : 'y'
        }
        handleScroll(event, targetElement, axis)
      }
    }

    if (!documentListenerAdded) {
      document.addEventListener('touchmove', preventDefault, hasPassiveEvents ? { passive: false } : undefined)
      documentListenerAdded = true
    }
  } else {
    setOverflowHidden(options)
  }
}

export const clearAllBodyScrollLocks = () => {
  if (isIosDevice) {
    // Clear all locks ontouchstart/ontouchmove handlers, and the references.
    locks.forEach(lock => {
      // eslint-disable-next-line no-param-reassign
      lock.targetElement.ontouchstart = null
      // eslint-disable-next-line no-param-reassign
      lock.targetElement.ontouchmove = null
    })

    if (documentListenerAdded) {
      document.removeEventListener('touchmove', preventDefault, hasPassiveEvents ? { passive: false } : undefined)
      documentListenerAdded = false
    }

    // Reset initial clientY.
    initialClient = { x: -1, y: -1 }
    axis = null
  } else {
    restoreOverflowSetting()
  }

  locks = []
}

export const enableBodyScroll = targetElement => {
  if (!targetElement) {
    throw new Error(
      'enableBodyScroll unsuccessful - targetElement must be provided when calling enableBodyScroll on IOS devices.'
    )
  }

  locks = locks.filter(lock => lock.targetElement !== targetElement)

  if (isIosDevice) {
    // eslint-disable-next-line no-param-reassign
    targetElement.ontouchstart = null
    // eslint-disable-next-line no-param-reassign
    targetElement.ontouchmove = null

    if (documentListenerAdded && locks.length === 0) {
      document.removeEventListener('touchmove', preventDefault, hasPassiveEvents ? { passive: false } : undefined)
      documentListenerAdded = false
    }
  } else if (!locks.length) {
    restoreOverflowSetting()
  }
}
