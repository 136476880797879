import Vue from 'vue'

export const notifications = Vue.observable({
  list: []
})

export const notificationService = {
  list: notifications.list,

  create({ type, text }) {
    return new Promise(resolve => {
      this.list.push({ type, text, close: resolve })
    })
  },
  success(text) {
    return this.create({ text, type: 'success' })
  },
  error(text) {
    return this.create({ text, type: 'error' })
  },

  errorWithFields({ title, listTitle, errorFields, buttonText }) {
    return new Promise(resolve => {
      this.list.push({ title, listTitle, errorFields, buttonText, close: resolve })
    })
  },

  shift() {
    this.list.shift()
  }
}

export default notificationService
