import { flatObject, getFormDataFrom, getStringCapitalize, toSnakeCaseObject } from '@/utils/common'
import { reformatDate } from '@/utils/formatters'
import { DRAFT_STATUS_ACCEPTED } from '@/constants/draftStatuses'
import { analyticsEvents } from '@/services/segment'
import { ROLE_AGENT, ROLE_OWNER } from '@/constants/roles'
import BaseHttpService from './base'

const generateCallParams = ({ agent, advert, name, phone, callDate, role, callSource, text }) => {
  return toSnakeCaseObject({
    agent: agent?.id || undefined,
    advert: advert?.[0]?.id || advert?.id || undefined,
    clientName: name || undefined,
    clientPhone: phone || undefined,
    callDate: callDate ? reformatDate(callDate) : undefined,
    contactType: role || undefined,
    callSource: callSource?.id || undefined,
    text: text || undefined
  })
}

class AgentsService extends BaseHttpService {
  select() {
    return this.client.get('/agents/select/')
  }

  getList() {
    return this.client.get('/agents/')
  }

  get(id) {
    return this.client.get(`/agents/${id}/`)
  }

  create(params) {
    const formData = getFormDataFrom(flatObject(toSnakeCaseObject(params)))
    return this.client.post('/agents/', formData).then(() => {
      analyticsEvents.inviteSent({
        inviteeEmail: params.email,
        inviteeFirstName: params.name,
        inviteeLastName: '',
        inviteeRole: getStringCapitalize(ROLE_AGENT)
      })
    })
  }

  update(id, params) {
    const formData = getFormDataFrom(flatObject(toSnakeCaseObject(params)))
    return this.client.patch(`/agents/${id}/`, formData)
  }

  // TODO: можно вынести в AgentsPlansService
  getPlan({ agent, month, year }) {
    return this.client.get('/agents/plans/', { params: { agent, month, year } })
  }

  updatePlan(planId, { month, year, showsCount, advertsCount }) {
    return this.client.put(`/agents/plans/${planId}/`, { month, year, showsCount, advertsCount })
  }

  // TODO: можно вынести в AgentsYearsService
  selectYears(id) {
    return this.client.get(`/agents/${id}/years-select/`)
  }

  // calls
  getCallById(id) {
    return this.client.get(`/agents/real-estate-calls/${id}/`)
  }

  getCallsList({
    limit,
    offset,
    agentId,
    callSourceId,
    query,
    phone,
    categoryId,
    priceLte,
    priceGte,
    dateFrom,
    dateTo,
    callType,
    orderBy,
    cancelToken
  }) {
    const params = toSnakeCaseObject({
      limit,
      offset,
      agent: agentId,
      name: query,
      callSource: callSourceId,
      advertCategory: categoryId,
      advertPriceLte: priceLte,
      advertPriceGte: priceGte,
      dateFrom,
      dateTo,
      callType,
      orderBy,
      phone
    })
    return this.client.get(`/agents/real-estate-calls/`, { params, cancelToken })
  }

  getCallDraft(versionId) {
    return this.client.get(`/agents/real-estate-calls/versions/${versionId}/`)
  }

  approveDraft(id, { initialNotificationId, draftStatus }) {
    if (draftStatus === DRAFT_STATUS_ACCEPTED) {
      return this.client.post(`/agents/real-estate-calls/versions/${id}/apply_revision/`, {
        initialNotificationId
      })
    }
    return this.client.post(`/agents/real-estate-calls/versions/${id}/reject_revision/`, {
      initialNotificationId
    })
  }

  createCall({ role, phone, name, agent, callSource, callDate, advert, text }, isUnique = false) {
    const params = generateCallParams({
      role,
      phone,
      name,
      agent,
      callSource,
      callDate,
      advert,
      text
    })
    return this.client.post(`/agents/real-estate-calls/`, params).then(result => {
      return analyticsEvents
        .leadAdded({
          leadType: getStringCapitalize(role),
          commentAdded: !!text,
          leadStatus: getStringCapitalize(result.callType)
        })
        .then(() => {
          if (isUnique) {
            if (role === ROLE_OWNER) {
              analyticsEvents.propertyOwnerAdded({ name, phone })
            } else analyticsEvents.customerAdded({ name, phone })
          }
        })
        .then(() => {
          return result
        })
    })
  }

  bulkUpdateCalls(ids, { callSource, agent, adverts, callDate }) {
    const payload = {
      advertCallIds: ids,
      updatedAdvertCallData: {
        callSource: callSource?.id || undefined,
        agent: agent?.id || undefined,
        adverts: adverts?.id || undefined,
        callDate: callDate ? reformatDate(callDate) : undefined
      }
    }
    return this.client.patch(`/agents/real-estate-calls/bulk-update/`, payload)
  }

  bulkDeleteCalls(ids) {
    const data = {
      ids
    }
    return this.client.delete(`/agents/real-estate-calls/bulk-delete/`, { data })
  }

  updateCall(id, { role, phone, name, agent, callSource, callDate, advert, text }) {
    const params = generateCallParams({
      role,
      phone,
      name,
      agent,
      callSource,
      callDate,
      advert,
      text
    })
    return this.client.patch(`/agents/real-estate-calls/${id}/`, params)
  }

  deleteCall(id) {
    return this.client.delete(`/agents/real-estate-calls/${id}/`)
  }

  // shows
  getShowById(id) {
    return this.client.get(`/agents/real-estate-shows/${id}/`)
  }

  getShowsList({
    limit,
    offset,
    agentId,
    query,
    phone,
    categoryId,
    priceLte,
    priceGte,
    dateFrom,
    dateTo,
    status,
    orderBy,
    cancelToken
  }) {
    const params = toSnakeCaseObject({
      limit,
      offset,
      agent: agentId,
      clientName: query,
      clientPhone: phone,
      advertCategory: categoryId,
      advertPriceLte: priceLte,
      advertPriceGte: priceGte,
      dateFrom,
      dateTo,
      status,
      orderBy
    })
    return this.client.get(`/agents/real-estate-shows/`, { params, cancelToken })
  }

  getShowDraft(versionId) {
    return this.client.get(`/agents/real-estate-shows/versions/${versionId}/`)
  }

  approveShow(id, { initialNotificationId, draftStatus }) {
    if (draftStatus === DRAFT_STATUS_ACCEPTED) {
      return this.client.post(`/agents/real-estate-shows/versions/${id}/apply_revision/`, {
        initialNotificationId
      })
    }
    return this.client.post(`/agents/real-estate-shows/versions/${id}/reject_revision/`, {
      initialNotificationId
    })
  }

  createShow({ agent, showDate, showTime, adverts, description, account, photos }, fromCustomerPage = false) {
    const params = toSnakeCaseObject({
      agent: agent?.id || undefined,
      adverts: (adverts?.length && adverts?.map(obj => obj.id)) || undefined,
      description: description || undefined,
      showDate: showDate || undefined,
      showTime: showTime || undefined,
      account: account?.id || undefined,
      photos: photos.length
        ? photos.map(photo => {
            return {
              backgroundFileId: photo.id || photo.backgroundFileId
            }
          })
        : []
    })

    return this.client.post(`/agents/real-estate-shows/`, params).then(result => {
      const analyticStatusesMap = { planned: 'Sheduled', completed: 'Done' }
      analyticsEvents.customerProfileUpdated({
        postAdded: 'Tour'
      })
      analyticsEvents.tourAdded({
        tourStatus: analyticStatusesMap[result.status],
        pageCategory: fromCustomerPage ? 'Customer Profile' : 'Table',
        commentAdded: !!description,
        actOfViewingAttached: !!photos.length
      })

      return result
    })
  }

  updateShow({
    id,
    agent,
    showDate,
    showTime,
    advert,
    description,
    account,
    photosDelete,
    photosAdd,
    showStatus,
    changedReminderFields
  }) {
    const params = toSnakeCaseObject({
      agent: agent?.id || undefined,
      advert: advert?.id || undefined,
      description: description ?? undefined,
      showDate: showDate || undefined,
      showTime: showTime || undefined,
      account: account?.id || undefined,
      showStatus: showStatus || undefined,
      photosAdd: photosAdd?.length
        ? photosAdd.map(photo => {
            return {
              backgroundFileId: photo
            }
          })
        : undefined,
      photosDelete: photosDelete?.length ? photosDelete : undefined
    })
    return this.client.patch(`/agents/real-estate-shows/${id}/`, params).then(result => {
      analyticsEvents.reminderChanged({
        reminderType: 'Tour',
        timeChanged: changedReminderFields.time,
        dateChanged: changedReminderFields.date,
        commentChanged: changedReminderFields.comment
      })
      return result
    })
  }

  bulkUpdateShows(ids, { agent, showDate, advert, showStatus }) {
    const payload = {
      advertShowIds: ids,
      updatedAdvertShowData: {
        agent: agent?.id || undefined,
        advert: advert?.id || undefined,
        showDate: showDate || undefined,
        showStatus: showStatus || undefined
      }
    }
    return this.client.patch(`/agents/real-estate-shows/bulk-update/`, payload)
  }

  bulkDeleteShows(ids) {
    const data = {
      ids
    }
    return this.client.delete(`/agents/real-estate-shows/bulk-delete/`, { data })
  }

  deleteShow(id) {
    return this.client.delete(`/agents/real-estate-shows/${id}/`)
  }
}

export default AgentsService
