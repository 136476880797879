import { toSnakeCaseObject } from '@/utils/common'
import BaseHttpService from './base'

class StatisticService extends BaseHttpService {
  getSummaryInformation({ dateFrom, dateTo, dateRangeType, categoryId }) {
    const params = { dateFrom, dateTo, dateRangeType, categoryId }
    return this.client.get('/statistic/summary-information/', { params: toSnakeCaseObject(params) })
  }

  getUnpopularRealtyEstate(params) {
    return this.client.get('/statistic/unpopular-real-estate/', { params: toSnakeCaseObject(params) })
  }

  getBestAgentsList(params) {
    return this.client('/statistic/best-agents/', { params: toSnakeCaseObject(params) })
  }

  getIneffectiveAgentsList(params) {
    return this.client('/statistic/ineffective-agents/', { params: toSnakeCaseObject(params) })
  }

  // calls

  getCallsList(params) {
    return this.client.get('/statistic/calls/', { params: toSnakeCaseObject(params) })
  }

  getCallsGraph(params) {
    return this.client.get('/statistic/calls/graph/', { params: toSnakeCaseObject(params) })
  }

  getCallsCategoriesGraph(params) {
    return this.client.get('/statistic/calls/graph/categories/', { params: toSnakeCaseObject(params) })
  }

  getCallsAgentsGraph(params) {
    return this.client.get('/statistic/calls/graph/agents/', { params: toSnakeCaseObject(params) })
  }

  getCallsConversionGraph({ dateFrom, dateTo, dateRangeType, categoryId }) {
    const params = { dateFrom, dateTo, dateRangeType, advertCategoryId: categoryId }
    return this.client.get('/statistic/calls/conversion/', { params: toSnakeCaseObject(params) })
  }

  getCallsSourcesGraph({ dateFrom, dateTo, dateRangeType, categoryId }) {
    const params = { dateFrom, dateTo, dateRangeType, advertCategoryId: categoryId }
    return this.client.get('/statistic/calls/source/', { params: toSnakeCaseObject(params) })
  }

  getCallsAdvertAveragePricesGraph({ dateFrom, dateTo, dateRangeType, agentId, isSimilarRange }) {
    const params = { dateFrom, dateTo, dateRangeType, agentId, isSimilarRange }
    return this.client.get('/statistic/calls/advert-average-price/', { params: toSnakeCaseObject(params) })
  }

  // shows
  getShowsList(params) {
    return this.client.get('/statistic/shows/', { params: toSnakeCaseObject(params) })
  }

  getShowsGraph(params) {
    return this.client.get('/statistic/shows/graph/', { params: toSnakeCaseObject(params) })
  }

  getShowsCalendarDates(params) {
    return this.client.get('/statistic/shows/calendar/', { params: toSnakeCaseObject(params) })
  }

  getShowsCalendarDay(params) {
    return this.client.get('/statistic/shows/calendar/day/', { params: toSnakeCaseObject(params) })
  }

  getShowsCategoriesGraph(params) {
    return this.client.get('/statistic/shows/graph/categories/', { params: toSnakeCaseObject(params) })
  }

  getShowsAgentsGraph(params) {
    return this.client.get('/statistic/shows/graph/agents/', { params: toSnakeCaseObject(params) })
  }

  // real-estate
  getAdvertsGraph(params) {
    return this.client.get('/statistic/real-estate/graph/', { params: toSnakeCaseObject(params) })
  }

  getAdvertsCategoriesGraph(params) {
    return this.client.get('/statistic/real-estate/graph/categories/', { params: toSnakeCaseObject(params) })
  }

  getAdvertsAgentsGraph() {
    return this.client.get('/statistic/real-estate/graph/agents/')
  }

  getAdvertsAgentsAveragePriceGraph() {
    return this.client.get('/statistic/real-estate/graph/agents/average-price/')
  }

  getPopularRealtyEstate(params) {
    return this.client.get('/statistic/popular-real-estate/', { params: toSnakeCaseObject(params) })
  }

  getBestAgentsAdvertList(params) {
    return this.client('/statistic/real-estate/best-agents/', { params: toSnakeCaseObject(params) })
  }

  getIneffectiveAgentsAdvertList(params) {
    return this.client('/statistic/real-estate/ineffective-agents/', { params: toSnakeCaseObject(params) })
  }

  getBestAgentsCallsList(params) {
    return this.client('/statistic/calls/best-agents/', { params: toSnakeCaseObject(params) })
  }

  getIneffectiveAgentsCallsList(params) {
    return this.client('/statistic/calls/ineffective-agents/', { params: toSnakeCaseObject(params) })
  }

  getBestAgentsShowsList(params) {
    return this.client('/statistic/shows/best-agents/', { params: toSnakeCaseObject(params) })
  }

  getIneffectiveAgentsShowsList(params) {
    return this.client('/statistic/shows/ineffective-agents/', { params: toSnakeCaseObject(params) })
  }
}

export default StatisticService
