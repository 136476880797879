import { OWNER_STATUS_ARCHIVE } from '@/constants/statuses/owners'
import { getStringCapitalize, toSnakeCaseObject } from '@/utils/common'
import { DRAFT_STATUS_ACCEPTED } from '@/constants/draftStatuses'
import { COMMENT, FILE, OUTGOING_CALL } from '@/constants/ownerInteractionHistory'
import { analyticsEvents } from '@/services/segment'
import BaseHttpService from './base'

class OwnersService extends BaseHttpService {
  create({ name, phone, email, agent, adverts, status, callSource }) {
    const newOwnerData = {
      name,
      email,
      phone,
      ownerProfile: {
        agent: agent?.id || undefined,
        adverts: adverts.map(obj => obj.id),
        status: status.id,
        callSource: callSource.id
      }
    }
    return this.client.post('/owners/', newOwnerData).then(() => {
      analyticsEvents.propertyOwnerAdded({ name, phone, email })
    })
  }

  update(id, params, isEditProfile = false, changedValues) {
    const { name, email, phone, agent, adverts, status, callSource } = params
    const payload = isEditProfile
      ? {
          name: name || undefined,
          email: email || undefined,
          phone: phone || undefined,
          ownerProfile: {
            agent: agent?.id || undefined,
            adverts: (adverts?.length && adverts?.map(obj => obj.id)) || [],
            status: status?.id || undefined,
            callSource: callSource?.id || undefined
          }
        }
      : params

    return this.client.patch(`/owners/${id}/`, payload).then(result => {
      if (isEditProfile && changedValues?.profileFields?.length) {
        analyticsEvents.propertyOwnerProfileChanged({
          informationChanged: changedValues.profileFields
        })
      }
      if (changedValues?.adverts || payload?.files?.length) {
        analyticsEvents.propertyOwnerProfileUpdated({
          numberOfPropertyAdded: changedValues?.adverts || undefined,
          numberOfDocumentsUploaded: payload?.files?.length || undefined
        })
      }
      return result
    })
  }

  bulkUpdate(ids, { status, callSource, agent }) {
    const payload = {
      ownerIds: ids,
      updatedOwnerData: {
        ownerProfile: {
          callSource: callSource?.id || undefined,
          agent: agent?.id || undefined,
          status: status?.id || undefined
        }
      }
    }
    return this.client.patch(`/owners/bulk-update/`, payload)
  }

  getList({
    limit,
    offset,
    agentId,
    query,
    phone,
    categoryId,
    status,
    priceLte,
    priceGte,
    dateFrom,
    dateTo,
    lastReminderFrom,
    lastReminderTo,
    isDocumentsUploaded,
    orderBy,
    hasEgrp,
    hasAgreement,
    hasTitleDocument,
    cancelToken
  }) {
    const params = toSnakeCaseObject({
      limit,
      offset,
      agent: agentId,
      name: query,
      advertCategory: categoryId,
      priceLte,
      priceGte,
      dateFrom,
      dateTo,
      lastReminderFrom,
      lastReminderTo,
      isDocumentsUploaded,
      orderBy,
      hasEgrp,
      hasAgreement,
      hasTitleDocument,
      phone
    })
    if (status === OWNER_STATUS_ARCHIVE) {
      return this.client.get('/owners/archived/', { params, cancelToken })
    }
    return this.client.get('/owners/', {
      params,
      cancelToken
    })
  }

  getOwnerById(id) {
    return this.client.get(`/owners/${id}/`, {
      cache: {
        maxAge: 5 * 1000
      }
    })
  }

  getOwnerDraft(versionId) {
    return this.client.get(`/owners/versions/${versionId}/`)
  }

  getOwnerDraftChanges(versionId) {
    return this.client.get(`/owners/versions/${versionId}/get_changes/`)
  }

  bulkArchiveOwners(ids) {
    const payload = {
      ownerIds: ids,
      isArchived: true
    }
    return this.client.patch(`/owners/archive/`, payload)
  }

  bulkActivateOwners(ids) {
    const payload = {
      ownerIds: ids,
      isArchived: false
    }
    return this.client.patch(`/owners/archive/`, payload)
  }

  getOwnerStatusesMap() {
    return this.client.get(`/owners/choices/status/`)
  }

  getCallSourcesMap() {
    return this.client.get(`/owners/choices/call_source/`)
  }

  createHistory(id, { type, text, reminderDatetime, files }) {
    const payload = { type, text, reminderDatetime, files }
    return this.client.post(`/owners/${id}/history/`, payload).then(() => {
      if ([COMMENT, FILE].includes(type)) {
        analyticsEvents.commentAdded({ fileAttached: !!files?.length, pageCategory: 'Property Owner Profile' })
        analyticsEvents.propertyOwnerProfileUpdated({
          postAdded: getStringCapitalize(type === COMMENT ? COMMENT : FILE)
        })
      }
      if (type === OUTGOING_CALL) {
        // todo: возвращать с бэка статус
        analyticsEvents.outCallAdded({
          outCallStatus: '',
          commentAdded: !!text,
          pageCategory: 'Property Owner Profile'
        })
        analyticsEvents.propertyOwnerProfileUpdated({
          postAdded: 'Out Call'
        })
      }
    })
  }

  getHistory(id) {
    return this.client.get(`/owners/${id}/history/`)
  }

  updateHistory(ownerId, id, { type, text, reminderDatetime, files, deletedFiles, changedFields }) {
    const payload = { type, text, reminderDatetime, files, deletedFiles }
    return this.client.patch(`/owners/${ownerId}/history/${id}/`, payload).then(() => {
      if (type === OUTGOING_CALL) {
        analyticsEvents.reminderChanged({
          reminderType: 'Out Call',
          timeChanged: changedFields.time,
          dateChanged: changedFields.date,
          commentChanged: changedFields.comment
        })
      }
    })
  }

  deleteHistory(ownerId, id) {
    return this.client.delete(`/owners/${ownerId}/history/${id}/`)
  }

  approveHistoryDraft(id, ownerId, draftStatus) {
    if (draftStatus === DRAFT_STATUS_ACCEPTED) {
      return this.client.post(`/owners/${ownerId}/history/versions/${id}/apply_revision/`)
    }
    return this.client.post(`/owners/${ownerId}/history/versions/${id}/reject_revision/`)
  }

  approveDraft(id, { initialNotificationId, draftStatus }) {
    if (draftStatus === DRAFT_STATUS_ACCEPTED) {
      return this.client.post(`/owners/versions/${id}/apply_revision/`, {
        initialNotificationId
      })
    }
    return this.client.post(`/owners/versions/${id}/reject_revision/`, {
      initialNotificationId
    })
  }
}

export default OwnersService
