import { ValidationProvider, ValidationObserver } from 'vee-validate'
import installVeeValidateRules from '@/plugins/vee-validate/rules'

const VeeValidate = {
  install(Vue) {
    Vue.component('ValidationProvider', ValidationProvider)
    Vue.component('ValidationObserver', ValidationObserver)

    installVeeValidateRules()
  }
}

export default VeeValidate
