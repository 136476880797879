import { FETCH_TENANT_INFO, MODULE_SESSION } from '@/store/modules/session/session.types'
import { analytics } from '@/services/segment'

export default function loadTenantInfo({ next, store }) {
  const isTenantInfoLoaded = store.getters[`${MODULE_SESSION}/isTenantInfoLoaded`]
  if (isTenantInfoLoaded) {
    return next()
  }
  return store
    .dispatch(`${MODULE_SESSION}/${FETCH_TENANT_INFO}`)
    .then(() => {
      const tenantInfo = store.state[MODULE_SESSION].tenantModel
      return analytics.group(tenantInfo.id || '', {
        account_name: tenantInfo.organizationName || '',
        organization_status: tenantInfo.status || '',
        organization_users_count: tenantInfo.organizationUsersCount || '',
        subscription_plan_name: tenantInfo.subscription?.planName || '',
        subscription_plan_value: tenantInfo.subscription?.planValue || '',
        subscription_plan_type: tenantInfo.subscription?.planType || ''
      })
    })
    .then(() => {
      next()
    })
    .catch(() => next(false))
}
