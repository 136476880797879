<template>
  <div id="app" :class="`theme-${isNmh ? 'nmh' : 'agentik'}`">
    <media-query-provider :queries="queries">
      <router-view />
      <v-confirmation-group />
      <v-notification-group />
      <v-alert-group />
    </media-query-provider>
    <v-loader
      v-if="isGlobalLoading"
      :color="isNmh ? $options.MAIN_THEME_COLOR.nmh : $options.MAIN_THEME_COLOR.agentik"
      :active="isGlobalLoading"
      is-full-screen
    />
  </div>
</template>

<script>
import { MAIN_THEME_COLOR } from '@/constants/colors'
import { MediaQueryProvider } from 'vue-component-media-queries'
import VNotificationGroup from '@/components/common/notifications/VNotificationGroup.vue'
import VConfirmationGroup from '@/components/common/confirmations/VConfirmationGroup.vue'
import VAlertGroup from '@/components/common/alerts/VAlertGroup.vue'
import VLoader from '@/components/common/VLoader.vue'
import { loaderState } from '@/services/loading'
import { alertService } from '@/services/alert'
import { mapGetters } from 'vuex'
import { MODULE_SESSION } from '@/store/modules/session/session.types'
import { ERROR_CODE_NETWORK_ERROR } from './constants/http'

const queries = {
  desktop: '(min-width: 1025px)',
  desktopMin: '(min-width: 1025px) and (max-width: 1200px)',
  tablet: '(min-width: 481px) and (max-width: 1024px)',
  mobile: '(max-width: 480px)'
}

export default {
  MAIN_THEME_COLOR,
  name: 'App',
  components: {
    MediaQueryProvider,
    VNotificationGroup,
    VConfirmationGroup,
    VAlertGroup,
    VLoader
  },
  data: () => {
    return {
      queries
    }
  },
  computed: {
    ...mapGetters({
      isNmh: `${MODULE_SESSION}/isNmh`
    }),
    isGlobalLoading() {
      return loaderState.global
    }
  },
  created() {
    window.onunhandledrejection = event => {
      if (event.reason?.status === ERROR_CODE_NETWORK_ERROR) {
        alertService.add({
          type: 'error',
          text: 'Некоторые возможности страницы недоступны. Наши разработчики уже устраняют проблему.'
        })
      } else {
        throw event
      }
    }
  },
  beforeDestroy() {
    window.onunhandledrejection = undefined
  }
}
</script>
