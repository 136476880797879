// Module name
export const MODULE_REMINDERS = 'reminders'

// Actions
export const INIT_REMINDER_FETCHING = 'initReminderFetching'
export const FETCH_EXPIRED_REMINDERS_COUNT = 'fetchExpiredRemindersCount'

// Mutations
export const RESET_STATE = 'resetState'
export const SET_INTERVAL = 'setInterval'
export const SET_EXPIRED_COUNT = 'setExpiredCount'
export const SET_REQUEST_PROMISE = 'setRequestPromise'
