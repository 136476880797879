import Vue from 'vue'
import Vuex from 'vuex'
import { LOGOUT } from '@/store/common/actions.types'
import session from './modules/session/session'
import villages from './modules/villages/villages'
import notifications from './modules/notifications/notifications'
import reminders from './modules/reminders/reminders'
import realty from './modules/realty/realty'
import article from './modules/article/article'

import { MODULE_NOTIFICATIONS } from './modules/notifications/notifications.types'
import { MODULE_REMINDERS } from './modules/reminders/reminders.types'
import { MODULE_SESSION } from './modules/session/session.types'
import { MODULE_VILLAGES } from './modules/villages/villages.types'
import { MODULE_REALTY } from './modules/realty/realty.types'
import { MODULE_ARTICLES } from './modules/article/article.types'

import state from './state'
import getters from './getters'

Vue.use(Vuex)

const modules = {
  [MODULE_SESSION]: session,
  [MODULE_VILLAGES]: villages,
  [MODULE_NOTIFICATIONS]: notifications,
  [MODULE_REMINDERS]: reminders,
  [MODULE_REALTY]: realty,
  [MODULE_ARTICLES]: article
}

export default new Vuex.Store({
  state,
  getters,
  modules,
  actions: {
    [LOGOUT]: ({ dispatch }) => {
      Object.entries(modules).forEach(([name, module]) => {
        if (module.actions[LOGOUT]) {
          dispatch(`${name}/${LOGOUT}`)
        }
      })
    }
  }
})
