<template>
  <transition name="slide" v-bind="$attrs" v-on="$listeners">
    <slot></slot>
  </transition>
</template>

<script>
export default {
  name: 'SlideTransition'
}
</script>

<style lang="scss">
.slide-enter-active,
.slide-leave-active {
  transition: all 0.3s;
}

.slide-enter,
.slide-leave-to {
  transform: translate(0, 10%);
  opacity: 0;
}
</style>
