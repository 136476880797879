export const MODULE_ARTICLES = 'articles'

// ACTION TYPES
export const RESET_STATE = 'resetState'
export const FETCH_ARTICLE_BY_ID = 'fetchArticleById'
export const UPDATE_ARTICLE = 'updateArticle'
export const CREATE_ARTICLE = 'createArticle'

// STATE TYPES
export const GENERAL_SECTION = 'generalSection'
export const CONTENT_SECTION = 'contentSection'

// MUTATION TYPES
export const SET_REALTY = 'setRealty'
export const PATCH_SECTION_INFO = 'patchSectionInfo'
export const RESET_STATE_MUTATION = 'resetStateMutation'

// GETTER TYPES
export const FULL_ARTICLE_MODEL = 'fullArticleModel'
