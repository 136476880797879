<template>
  <vue-element-loading
    v-scroll-lock="active"
    v-bind="{ active, color, isFullScreen, backgroundColor, size, text }"
    duration="0.6"
    class="v-loader"
    spinner="spinner"
  />
</template>

<script>
import ScrollLock from '@/directives/body-scroll-lock'
import VueElementLoading from 'vue-element-loading'

export default {
  name: 'VLoader',
  components: { VueElementLoading },
  directives: {
    ScrollLock
  },
  props: {
    active: { type: Boolean, default: false },
    isFullScreen: { type: Boolean, default: false },
    backgroundColor: { type: String, default: 'rgba(64, 64, 66, 0.05)' },
    color: { type: String, default: '#ffca43' },
    size: { type: String, default: '64' },
    text: { type: String, default: '' }
  }
}
</script>
