import { MODULE_SESSION } from '@/store/modules/session/session.types'
import { ROLE_AGENT, ROLE_OWNER } from '@/constants/roles'
import { analytics, analyticsEvents } from '@/services/segment'
import { getUnixTimestampDate, toSnakeCaseObject } from '@/utils/common'

export default function signInAnalytics({ next, to, store }) {
  if (!to.params.trackSignIn) {
    next()
    return
  }

  const { id, role, name, email, statistics } = store.state[MODULE_SESSION].model
  const loginMethod = role === ROLE_OWNER ? 'Phone' : 'Email'
  const identifyParams = {
    userId: id,
    firstName: name,
    lastName: '',
    email,
    country: 'RU - Россия',
    signupUpAt: getUnixTimestampDate(),
    userRole: role === ROLE_OWNER ? 'Property Owner' : role.charAt(0).toUpperCase() + role.slice(1),
    userType: 'Invited'
  }
  let traits = identifyParams
  if (role === ROLE_AGENT) {
    const { leadAdded, tourAdded, propertyAdded, draftPropertyAdded, propertyOwnerAdded, customerAdded } = statistics
    const agentStatisticsParams = {
      propertyAdded,
      leadAdded,
      customerAdded,
      tourAdded,
      propertyOwnerAdded,
      draftPropertyAdded
    }
    traits = { ...identifyParams, ...agentStatisticsParams }
  }
  analytics
    .identify(id, toSnakeCaseObject(traits))
    .then(() => {
      return analyticsEvents.signedIn({ loggedInAt: getUnixTimestampDate(), loginMethod })
    })
    .finally(() => next())
}
