import Vue from 'vue'

export const confirmations = Vue.observable({
  list: []
})

export const confirmationService = {
  list: confirmations.list,

  ask({ title, confirmText, cancelText, customComponent }) {
    return new Promise((confirm, cancel) => {
      this.list.push({ title, confirmText, cancelText, confirm, cancel, customComponent })
    })
  },

  shift() {
    this.list.shift()
  }
}

export default confirmationService
