/**
 * To camel case from snake case (for a server response)
 */
import cloneDeepLodash from 'lodash.clonedeep'
import isEqualLodash from 'lodash.isequal'
import debounceLodash from 'lodash.debounce'
import mergeLodash from 'lodash.merge'
import {
  formatCallObjectForComparison,
  formatClientObjectForComparison,
  formatShowObjectForComparison
} from '@/utils/formatters'
import { endOfWeek, startOfWeek } from 'date-fns'
import { ADVERTS_FIELDS_BY_CATEGORIES } from '@/constants/objectRealtyOptions'

export function getPercentage(value, targetValue) {
  const result = targetValue ? (value / targetValue) * 100 : 0
  return Math.round(result)
}

export function toCamelCaseString(str) {
  return str ? str.replace(/_([a-z])/g, g => g[1].toUpperCase()) : str
}

export function getUnixTimestampDate() {
  return Math.floor(Date.now() / 1000)
}

export function toCamelCaseObject(object) {
  if (object === null || typeof object !== 'object' || object instanceof Date) {
    return object
  }

  if (Array.isArray(object)) {
    return object.map(toCamelCaseObject)
  }

  return Object.entries(object).reduce((o, [key, value]) => {
    const newObject = { ...o }
    const newValue = toCamelCaseObject(value)
    const newKey = toCamelCaseString(key)
    newObject[newKey] = newValue
    return newObject
  }, {})
}

/**
 * To snake case from camel case (for a server request)
 */

export function toSnakeCaseString(str) {
  return str ? str.replace(/[A-Z]/g, g => `_${g[0].toLowerCase()}`) : str
}

export function toSnakeCaseObject(object) {
  if (object === null || typeof object !== 'object' || object instanceof Date || object instanceof File) {
    return object
  }

  if (Array.isArray(object)) {
    return object.map(toSnakeCaseObject)
  }

  return Object.entries(object).reduce((o, [key, value]) => {
    const newObject = { ...o }
    const newValue = toSnakeCaseObject(value)
    const newKey = toSnakeCaseString(key)
    newObject[newKey] = newValue
    return newObject
  }, {})
}

export function shortifyName(name) {
  if (typeof name === 'string' && !!name) {
    const parts = name.split(' ')
    if (parts.length === 2) {
      return `${parts[1]} ${parts[0][0]}.`
    }
  }
  return name
}

export function isFieldsNotChanged(initialObject, localObject, { isAdmin = false, objectType = 'client' }) {
  const validTypes = ['show', 'call', 'client']
  if (validTypes.includes(objectType)) {
    const compareObjectsFormattersMap = {
      show: formatShowObjectForComparison,
      call: formatCallObjectForComparison,
      client: formatClientObjectForComparison
    }
    return isEqualLodash(
      compareObjectsFormattersMap[objectType](initialObject, { isAdmin }),
      compareObjectsFormattersMap[objectType](localObject, { isAdmin })
    )
  }
  return false
}

export function isObject(object) {
  return Object.prototype.toString.call(object) === '[object Object]'
}

export function getChangedCategoriesForAdverts(changes) {
  const result = {}
  if (changes) {
    const changedFields = Object.keys(changes)
    Object.keys(ADVERTS_FIELDS_BY_CATEGORIES).forEach(key => {
      changedFields.forEach(field => {
        if (!result[key]) result[key] = !!ADVERTS_FIELDS_BY_CATEGORIES[key].includes(field)
      })
    })
  }
  return result
}

export function isString(value) {
  return typeof value === 'string'
}

export function isFunction(value) {
  return typeof value === 'function'
}

export function isBoolean(value) {
  return typeof value === 'boolean'
}

export function flatObject(nested, path = '') {
  return Object.entries(nested).reduce((obj, [key, value]) => {
    const newPath = `${path}${path ? '.' : ''}${key}`
    const sourcesObject = isObject(value) ? flatObject(value, newPath) : { [newPath]: value }
    return { ...obj, ...sourcesObject }
  }, {})
}

export function getFormDataFrom(object) {
  const formData = new FormData()
  Object.keys(object).forEach(key => {
    const value = object[key]
    if (Array.isArray(value)) {
      value.forEach(v => {
        if (isObject(v)) formData.append(key, JSON.stringify(v))
        else formData.append(key, v)
      })
    } else if (value !== undefined && value !== null) formData.append(key, value)
  })

  return formData
}

export function getPatchedFields(originalObject, newObject) {
  const patchedFields = {}
  Object.keys(newObject).forEach(key => {
    const originalObjectHasKey = Object.prototype.hasOwnProperty.call(originalObject, key)
    if (originalObjectHasKey) {
      if (isObject(originalObject[key])) {
        patchedFields[key] = getPatchedFields(originalObject[key], newObject[key])
        if (!Object.keys(patchedFields[key]).length) delete patchedFields[key]
      } else if (originalObject[key] !== newObject[key]) {
        patchedFields[key] = newObject[key]
      }
    } else {
      patchedFields[key] = newObject[key]
    }
  })
  return patchedFields
}

export function getFirstErrorForFields(errorsObj) {
  return Object.entries(errorsObj).reduce((acc, [key, value]) => {
    const formattedValue = isObject(value) ? getFirstErrorForFields(value) : value
    acc[key] = Array.isArray(value) ? value[0] : formattedValue
    return acc
  }, {})
}

export function pickProperties(propertiesNames, requestedProperties) {
  return requestedProperties.reduce((properties, propertyName) => {
    if (propertiesNames[propertyName]) {
      return {
        ...properties,
        [propertyName]: propertiesNames[propertyName]
      }
    }
    return properties
  }, {})
}

export function fillModel(model, rawData) {
  const newModel = {}
  Object.keys(model).forEach(key => {
    newModel[key] = rawData[key] !== undefined ? rawData[key] : model[key]
  })
  return newModel
}

export function cloneDeep(objects) {
  return cloneDeepLodash(objects)
}

export function debounce(func, wait) {
  return debounceLodash(func, wait)
}
export function deepMerge(object, sources) {
  return mergeLodash(object, sources)
}

export function isEqual(value, other) {
  return isEqualLodash(value, other)
}

export function generateUniqName() {
  return 'xxxxxxxxxxxxxxx'.replace(/[x]/g, () => {
    const r = Math.floor(Math.random() * 16)
    return r.toString(16)
  })
}

export function isNumber(n) {
  return !Number.isNaN(parseFloat(n)) && !Number.isNaN(n - 0)
}

export function createChartDataset({ data, color, label, filled = false, option, ...restParams }) {
  if (!data.length) return {}

  const optionProp = option || isObject(data[0]) ? Object.keys(data[0]).find(key => key !== 'date') : null

  return {
    color,
    filled,
    data: data.map(item => (optionProp ? item[optionProp] : item)),
    label,
    ...restParams
  }
}

export function getWeekRangeByDate(date) {
  const OPTIONS = { weekStartsOn: 1 }
  return [startOfWeek(date, OPTIONS), endOfWeek(date, OPTIONS)]
}

export function abbreviatedDate(dates) {
  return dates
    .map(date => date.split('-'))
    .map(item => item.filter((_, index) => index !== 2))
    .map(item => item.join('-'))
}

export function getStringCapitalize(string) {
  const lowerString = string.toLowerCase()
  return lowerString.charAt(0).toUpperCase() + lowerString.slice(1)
}
