import BaseHttpService from './base'

class VillagesService extends BaseHttpService {
  select() {
    return this.client.get('/villages/shorts/')
  }

  getList({ limit, offset, query, status }) {
    return this.client.get('/villages/', { params: { limit, offset, search: query, status } })
  }

  getShortList() {
    return this.client.get('/villages/shorts/')
  }

  getById(id) {
    return this.client.get(`/villages/${id}/`)
  }

  updateVillageSecondly(village, { changedVillageFiles }) {
    const params = {}
    if (changedVillageFiles && Object.keys(changedVillageFiles).length > 0) {
      params.changedVillageFiles = Object.entries(changedVillageFiles).reduce((acc, [key, value]) => {
        if (value.originalFileName) {
          const newId = village.files.find(f => f.source.split('/').pop() === value.originalFileName).id
          acc[newId] = value.name
        } else {
          acc[key] = value
        }
        return acc
      }, {})
    }

    if (Object.keys(params).length > 0) return this.client.patch(`/villages/${village.id}/`, params)
    return village
  }

  create(params) {
    const { changedVillageFiles, ...restParams } = params

    return this.client.post('/villages/', restParams).then(village => {
      return this.updateVillageSecondly(village, { changedVillageFiles })
    })
  }

  delete(id) {
    return this.client.delete(`/villages/${id}/`)
  }

  update(id, params) {
    const { changedVillageFiles, ...restParams } = params

    return this.client.patch(`/villages/${id}/`, restParams).then(village => {
      return this.updateVillageSecondly(village, { changedVillageFiles })
    })
  }

  selectInternalInfrastructureTags() {
    return this.client.get('/villages/tags/internal-infrastructure/')
  }

  selectExternalInfrastructureTags() {
    return this.client.get('/villages/tags/external-infrastructure/')
  }
}

export default VillagesService
