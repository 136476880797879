import { LOADING, LOADED, SET_ERROR } from '@/store/common/mutations.types'
import { pickCommonMutations } from '@/store/common/mutations'
import { VILLAGE_ARCHIVE_STATUS } from '@/constants/statuses/village'
import { LOGOUT, SAVE_SECTION_INFO } from '@/store/common/actions.types'
import { villagesService } from '@/services/http'
import { fillModel } from '@/utils/common'
import * as moduleTypes from './villages.types'

/*
  // Extra fields
  "slug": "string",
  "is_deleted": true,
*/

function generateBasicInfoModel() {
  return {
    cianId: null,
    isSpecial: false,
    status: VILLAGE_ARCHIVE_STATUS,
    type: null,
    name: null,
    internalInfrastructureTags: [],
    externalInfrastructureTags: [],
    year: null,
    territory: null,
    plotsCount: null,
    populationPercent: null,
    isGas: false,
    isElectricity: false,
    isSewerage: false,
    isWater: false,
    youtubeUrl: null
  }
}

function generateDescriptionInfoModel() {
  return {
    description: null
  }
}

function generateSeoInfoModel() {
  return {
    seoTitle: null,
    seoDescription: null
  }
}

function generatePlacementInfoModel() {
  return {
    coordinates: null,
    highway: null,
    settlement: null,
    area: null,
    distanceFromMkad: null,
    distanceFromHighway: null,
    distanceFromMetro: null,
    distanceFromBusStop: null
  }
}

function generatePhotosModel() {
  return {
    newVillageMedia: {},
    deletedVillageMedia: [],
    villageMediaOrders: {}
  }
}

function generateFilesModel() {
  return {
    newVillageFiles: {},
    deletedVillageFiles: [],
    changedVillageFiles: {}
  }
}

function generateAgentsModel() {
  return {
    agents: []
  }
}

export default {
  namespaced: true,

  state: {
    loading: false,
    id: null,
    files: [],
    photos: [],

    [moduleTypes.BASIC_INFO]: generateBasicInfoModel(),
    [moduleTypes.DESCRIPTION_INFO]: generateDescriptionInfoModel(),
    [moduleTypes.SEO_INFO]: generateSeoInfoModel(),
    [moduleTypes.PLACEMENT_INFO]: generatePlacementInfoModel(),
    [moduleTypes.PHOTOS]: generatePhotosModel(),
    [moduleTypes.FILES]: generateFilesModel(),
    [moduleTypes.RESPONSIBLE_AGENTS]: generateAgentsModel()
  },

  getters: {
    [moduleTypes.FULL_VILLAGE_MODEL]: state => {
      return {
        ...state[moduleTypes.BASIC_INFO],
        ...state[moduleTypes.DESCRIPTION_INFO],
        ...state[moduleTypes.SEO_INFO],
        ...state[moduleTypes.PLACEMENT_INFO],
        ...state[moduleTypes.PHOTOS],
        ...state[moduleTypes.FILES],
        ...state[moduleTypes.RESPONSIBLE_AGENTS]
      }
    }
  },

  mutations: {
    ...pickCommonMutations([LOADING, LOADED, SET_ERROR]),

    [moduleTypes.SET_VILLAGE]: (state, village) => {
      const basicModel = fillModel(generateBasicInfoModel(), village)
      const descriptionModel = fillModel(generateDescriptionInfoModel(), village)
      const seoModel = fillModel(generateSeoInfoModel(), village)
      const placementModel = fillModel(generatePlacementInfoModel(), village)
      const agentsModel = fillModel(generateAgentsModel(), village)

      state[moduleTypes.BASIC_INFO] = basicModel
      state[moduleTypes.DESCRIPTION_INFO] = descriptionModel
      state[moduleTypes.SEO_INFO] = seoModel
      state[moduleTypes.PLACEMENT_INFO] = placementModel
      state[moduleTypes.RESPONSIBLE_AGENTS] = agentsModel
      state[moduleTypes.FILES] = generateFilesModel()
      state[moduleTypes.PHOTOS] = generatePhotosModel()
      state.photos = village.photos
      state.files = village.files

      state.id = village.id
    },
    [moduleTypes.PATCH_SECTION_INFO]: (state, { type, sectionInfo }) => {
      state[type] = sectionInfo
    },
    [moduleTypes.RESET_STATE_MUTATION]: state => {
      state[moduleTypes.BASIC_INFO] = generateBasicInfoModel()
      state[moduleTypes.DESCRIPTION_INFO] = generateDescriptionInfoModel()
      state[moduleTypes.SEO_INFO] = generateSeoInfoModel()
      state[moduleTypes.PLACEMENT_INFO] = generatePlacementInfoModel()
      state[moduleTypes.PHOTOS] = generatePhotosModel()
      state[moduleTypes.FILES] = generateFilesModel()
      state[moduleTypes.RESPONSIBLE_AGENTS] = generateAgentsModel()
      state.id = null
      state.photos = []
      state.files = []
    }
  },

  actions: {
    [SAVE_SECTION_INFO]: ({ commit }, { type, sectionInfo }) => {
      commit(moduleTypes.PATCH_SECTION_INFO, { type, sectionInfo })
    },
    [moduleTypes.RESET_STATE]: ({ commit }) => {
      commit(moduleTypes.RESET_STATE_MUTATION)
    },

    [moduleTypes.FETCH_VILLAGE]: ({ commit }, id) => {
      commit(LOADING)
      return villagesService
        .getById(id)
        .then(result => {
          commit(moduleTypes.SET_VILLAGE, result)
        })
        .finally(() => {
          commit(LOADED)
        })
    },
    [moduleTypes.CREATE_VILLAGE]: ({ commit }, village) => {
      commit(LOADING)
      return villagesService
        .create(village)
        .then(result => {
          commit(moduleTypes.SET_VILLAGE, result)
        })
        .finally(() => commit(LOADED))
    },
    [moduleTypes.UPDATE_VILLAGE]: ({ state, commit }, village) => {
      const { id } = state

      commit(LOADING)
      return villagesService
        .update(id, village)
        .then(result => {
          commit(moduleTypes.SET_VILLAGE, result)
        })
        .finally(() => commit(LOADED))
    },

    [LOGOUT]: ({ dispatch }) => {
      dispatch(moduleTypes.RESET_STATE)
    }
  }
}
