import { extend } from 'vee-validate'
import { email, required, confirmed, min_value as minValue, min } from 'vee-validate/dist/rules'

export default function installVeeValidateRules() {
  extend('required', {
    ...required,
    message: 'Это поле обязательно'
  })
  extend('email', {
    ...email,
    message: 'Введите корректный email'
  })
  extend('confirmed', {
    ...confirmed,
    message: 'Пароль и подтверждение пароля не совпадают'
  })

  extend('min', {
    params: ['length'],
    ...min,
    message: `Длина пароля должна быть не менее {length} символов`
  })

  extend('CodeLength', {
    params: ['CodeLength'],
    validate: (value, args) => {
      if (value.length !== +args.CodeLength) return `Количество символов в коде должно быть ${args.CodeLength}`
      return true
    }
  })

  extend('minValue', {
    ...minValue,
    message: 'Значение должно быть не меньше {min}'
  })

  extend('minWithoutDividers', {
    params: ['min', 'divider'],
    validate: (value, args) => {
      const valueLength = value.split(args.divider).join('').length
      if (valueLength < args.min) return `Поле должно содержать не менее ${args.min} элементов`
      return true
    }
  })

  extend('arrayLength', {
    params: ['min', 'max'],
    validate: (value, args) => {
      const minL = args.min || 1
      const maxL = args.max || null
      const arValue = Array.from(value)
      const result = arValue.length >= minL && (maxL ? arValue.length <= maxL : true)
      return result || `Поле должно содержать от ${minL}${maxL ? ` до ${maxL}` : ``} элементов`
    }
  })

  extend('requiredIfValuesEmpty', {
    computesRequired: true,
    validate(value, otherFieldsValues) {
      return !!value || !!otherFieldsValues.find(fieldValue => fieldValue) || ''
    }
  })
}
