<template>
  <mounting-portal append mount-to="#alert-root">
    <fade-transition @after-leave="alertClosed">
      <div v-if="visible" class="v-alert">
        <button class="v-alert__close" type="button" aria-label="Close" @click="close">
          <v-icon-cross />
        </button>
        <div class="v-alert__title">{{ title }}</div>
        <div class="v-alert__text">{{ text }}</div>
      </div>
    </fade-transition>
  </mounting-portal>
</template>

<script>
import { MountingPortal } from 'portal-vue'
import VIconCross from '@/components/icons/VCross.vue'
import FadeTransition from '@/components/transitions/FadeTransition.vue'

const SUCCESS_ALERT = 'success'
const ERROR_ALERT = 'error'
const INFO_ALERT = 'info'

export default {
  name: 'VAlert',
  components: { VIconCross, FadeTransition, MountingPortal },
  props: {
    alert: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      closeTimer: null,
      visible: false
    }
  },
  computed: {
    type() {
      return this.alert?.type
    },
    text() {
      return this.alert?.text
    },
    title() {
      const titlesMap = {
        [SUCCESS_ALERT]: 'Успешно',
        [ERROR_ALERT]: 'Ошибка',
        [INFO_ALERT]: 'Информация'
      }

      return titlesMap[this.type]
    }
  },
  watch: {
    alert: {
      immediate: true,
      handler(val) {
        if (val) this.visible = true
      }
    },
    visible: {
      handler(val) {
        if (val) this.closeTimer = setTimeout(this.close, 8000)
      }
    }
  },
  beforeDestroy() {
    clearTimeout(this.closeTimer)
  },
  methods: {
    alertClosed() {
      this.$emit('closed')
    },
    close() {
      this.visible = false
    }
  }
}
</script>
