import { notificationsService } from '@/services/http'
import throwError from '@/utils/store'
import { LOADED, LOADING } from '@/store/common/mutations.types'
import { pickCommonMutations } from '@/store/common/mutations'
import { LOGOUT } from '@/store/common/actions.types'
import * as moduleTypes from './notifications.types'

const POOL_INTERVAL = 10 * 1000

export default {
  namespaced: true,
  state: {
    loading: false,
    unreadCount: 0,
    interval: null,
    requestPromise: null
  },
  mutations: {
    ...pickCommonMutations([LOADED, LOADING]),
    [moduleTypes.RESET_STATE]: state => {
      state.unreadCount = 0
      state.interval = null
      state.loading = false
      state.requestPromise = null
    },
    [moduleTypes.SET_INTERVAL]: (state, interval) => {
      state.interval = interval
    },
    [moduleTypes.SET_UNREAD_COUNT]: (state, count) => {
      state.unreadCount = count
    },
    [moduleTypes.SET_REQUEST_PROMISE]: (state, promise) => {
      state.requestPromise = promise
    }
  },
  actions: {
    [moduleTypes.INIT_NOTIFICATION_FETCHING]: ({ state, commit, dispatch }) => {
      if (state.interval) return

      dispatch(moduleTypes.FETCH_UNREAD_NOTIFICATIONS_COUNT)

      const interval = setInterval(() => {
        dispatch(moduleTypes.FETCH_UNREAD_NOTIFICATIONS_COUNT)
      }, POOL_INTERVAL)

      commit(moduleTypes.SET_INTERVAL, interval)
    },
    [moduleTypes.FETCH_UNREAD_NOTIFICATIONS_COUNT]: ({ state, commit }) => {
      if (state.loading) return state.requestPromise

      commit(LOADING)

      const requestPromise = notificationsService
        .getUnreadCount()
        .then(({ notificationsCount }) => commit(moduleTypes.SET_UNREAD_COUNT, notificationsCount))
        .catch(throwError(commit, 'Ошибка получения счетчика непрочитанных уведомлений'))
        .finally(() => {
          commit(LOADED)
          commit(moduleTypes.SET_REQUEST_PROMISE, null)
        })

      commit(moduleTypes.SET_REQUEST_PROMISE, requestPromise)
      return requestPromise
    },

    [LOGOUT]: ({ state, commit }) => {
      clearInterval(state.interval)
      commit(moduleTypes.RESET_STATE)
    }
  }
}
