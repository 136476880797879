// STORE TYPES
export const MODULE_SESSION = 'session'

//  ACTION TYPES
export const FETCH_USER_INFO = 'fetchUserInfo'
export const FETCH_TENANT_INFO = 'fetchTenantInfo'

// GETTERS TYPES
export const IS_ROLE_ADMIN = 'isRoleAdmin'

// MUTATIONS TYPES
export const SET_TENANT_MODEL = 'setTenantModel'

export const CHANGE_ACCESS_TOKEN = 'changeAccessToken'
export const SET_ACCESS_TOKEN = 'setAccessToken'
