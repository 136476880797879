import Vue from 'vue'

export const loaderState = Vue.observable({
  global: false,
  view: false
})

export const loadingService = {
  state: loaderState,

  setGlobalLoading(value) {
    this.state.global = value
  },

  setViewLoading(value) {
    this.state.view = value
  }
}
