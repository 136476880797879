import { LOADING, LOADED, SET_ERROR } from '@/store/common/mutations.types'
import { pickCommonMutations } from '@/store/common/mutations'
import { LOGOUT, SAVE_SECTION_INFO } from '@/store/common/actions.types'
import { articlesService } from '@/services/http'
import { cloneDeep, fillModel, isString } from '@/utils/common'
import { ARTICLE_STATUS_ARCHIVE } from '@/constants/statuses/article'
import * as moduleTypes from './article.types'

/*
  // Extra fields
  "slug": "string",
  "is_deleted": true,
*/

function generateGeneralModel() {
  return {
    title: null,
    status: ARTICLE_STATUS_ARCHIVE,
    previewImage: null,
    category: null,
    author: null,
    seoTitle: null,
    seoDescription: null
  }
}

function generateContentModel() {
  return {
    sections: [
      {
        title: null,
        linkUrl: null,
        linkTitle: null,
        youtubeUrl: null,
        text: null,
        photos: [],
        newSectionMedia: {},
        deletedSectionMedia: [],
        sectionMediaOrders: {}
      }
    ]
  }
}

function formatArticle(article) {
  const { previewImage, ...newArticle } = cloneDeep(article)

  const newSections = newArticle.sections.map(section => {
    const { photos, ...newSection } = cloneDeep(section)
    return newSection
  })

  if (isString(newArticle.previewImage)) {
    return { ...newArticle, sections: newSections }
  }

  const previewImageId = previewImage?.id
  return {
    ...newArticle,
    previewImage: previewImageId,
    sections: newSections
  }
}

export default {
  namespaced: true,

  state: {
    loading: false,
    id: null,

    [moduleTypes.GENERAL_SECTION]: generateGeneralModel(),
    [moduleTypes.CONTENT_SECTION]: generateContentModel()
  },

  getters: {
    [moduleTypes.FULL_ARTICLE_MODEL]: state => {
      return {
        ...state[moduleTypes.GENERAL_SECTION],
        ...state[moduleTypes.CONTENT_SECTION]
      }
    }
  },

  mutations: {
    ...pickCommonMutations([LOADING, LOADED, SET_ERROR]),

    [moduleTypes.SET_REALTY]: (state, article) => {
      const generalInfo = fillModel(generateGeneralModel(), article)
      const content = fillModel(generateContentModel(), article)

      state[moduleTypes.GENERAL_SECTION] = generalInfo
      state[moduleTypes.CONTENT_SECTION] = content

      state.id = article.id
    },
    [moduleTypes.PATCH_SECTION_INFO]: (state, { type, sectionInfo }) => {
      state[type] = sectionInfo
    },
    [moduleTypes.RESET_STATE_MUTATION]: state => {
      state[moduleTypes.GENERAL_SECTION] = generateGeneralModel()
      state[moduleTypes.CONTENT_SECTION] = generateContentModel()
      state.id = null
    }
  },

  actions: {
    [SAVE_SECTION_INFO]: ({ commit }, { type, sectionInfo }) => {
      commit(moduleTypes.PATCH_SECTION_INFO, { type, sectionInfo })
    },
    [moduleTypes.RESET_STATE]: ({ commit }) => {
      commit(moduleTypes.RESET_STATE_MUTATION)
    },

    [moduleTypes.FETCH_ARTICLE_BY_ID]: ({ commit }, id) => {
      commit(LOADING)
      return articlesService
        .getById(id)
        .then(result => {
          commit(moduleTypes.SET_REALTY, result)
        })
        .finally(() => {
          commit(LOADED)
        })
    },
    [moduleTypes.CREATE_ARTICLE]: ({ commit }, article) => {
      commit(LOADING)
      return articlesService
        .create(article)
        .then(result => {
          commit(moduleTypes.SET_REALTY, result)
        })
        .finally(() => commit(LOADED))
    },
    [moduleTypes.UPDATE_ARTICLE]: ({ state, commit }, article) => {
      const { id } = state

      commit(LOADING)

      return articlesService
        .update(id, formatArticle(article))
        .then(result => {
          commit(moduleTypes.SET_REALTY, result)
        })
        .finally(() => commit(LOADED))
    },

    [LOGOUT]: ({ dispatch }) => {
      dispatch(moduleTypes.RESET_STATE)
    }
  }
}
