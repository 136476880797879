<template>
  <svg
    class="icon icon--success"
    width="56"
    height="56"
    viewBox="0 0 56 56"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M22.4 39.2a1.9 1.9 0 01-1.3-3.2l18.6-18.7a1.9 1.9 0 112.7 2.7L23.7 38.6c-.3.4-.8.6-1.3.6z" />
    <path d="M22.4 39.2c-.5 0-1-.2-1.3-.6l-7.5-7.4a1.9 1.9 0 112.7-2.7l7.4 7.5a1.9 1.9 0 01-1.3 3.2z" fill="#404042" />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M0 28a28 28 0 1056 0 28 28 0 00-56 0zm3.7 0a24.3 24.3 0 1148.6 0 24.3 24.3 0 01-48.6 0z"
    />
  </svg>
</template>

<script>
export default {
  name: 'VSuccess'
}
</script>
