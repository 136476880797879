import BaseHttpService from './base'

class NotificationsHttpService extends BaseHttpService {
  getList({
    limit,
    offset,
    agent = null,
    dateFrom = null,
    dateTo = null,
    action = null,
    objectType = null,
    rejectOrApply = null
  }) {
    return this.client.get('/notifications/', {
      params: {
        limit,
        offset,
        author: agent,
        date_from: dateFrom,
        date_to: dateTo,
        action,
        object_type: objectType,
        reject_or_apply: rejectOrApply
      }
    })
  }

  getById(id) {
    return this.client.get(`/notifications/${id}/`)
  }

  getUnreadCount() {
    return this.client.get('/notifications/unread-count/')
  }
}

export default NotificationsHttpService
