// Module name
export const MODULE_NOTIFICATIONS = 'notifications'

// Actions
export const INIT_NOTIFICATION_FETCHING = 'initNotificationFetching'
export const FETCH_UNREAD_NOTIFICATIONS_COUNT = 'fetchUnreadNotificationsCount'

// Mutations
export const RESET_STATE = 'resetState'
export const SET_INTERVAL = 'setInterval'
export const SET_UNREAD_COUNT = 'setUnreadCount'
export const SET_REQUEST_PROMISE = 'setRequestPromise'
