import { MAP_DYNAMIC_TITLE_FETCH_PARAMS } from '@/constants/dynamicSeoTitleParams'

export default function setDynamicSeoTitle(staticPart) {
  return async ({ to, next }) => {
    const { service, action, routeParam } = MAP_DYNAMIC_TITLE_FETCH_PARAMS[to.name]
    await service[action](to.params[routeParam]).then(data => {
      document.title = `${data.name || ''}${data.name && staticPart ? ' | ' : ''}${staticPart || ''}`
    })
    next()
  }
}
