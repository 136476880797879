import { DARK_GRAY } from '@/constants/tagColors'

export const DEAL_TYPE_RENT = 'rent'
export const DEAL_TYPE_SALE = 'sale'

export const FETCH_TAGS_ADVERT_TYPE_VALUES_MAP = {
  housesAndPlots: 'HOUSE_TOWNHOUSE',
  apartments: 'APARTMENT'
}

export const HOUSE_TYPE_OPTIONS = [
  { value: 'brick', label: 'Кирпичный', forHouse: true, forFlat: true },
  { value: 'monolith', label: 'Монолитный', forHouse: true, forFlat: true },
  { value: 'wood', label: 'Деревянный', forHouse: true, forFlat: true },
  { value: 'aerocreteBlock', label: 'Газобетонный блок', forHouse: true, forFlat: false },
  { value: 'foamConcreteBlock', label: 'Пенобетонный блок', forHouse: true, forFlat: false },
  { value: 'gasSilicateBlock', label: 'Газосиликатный блок', forHouse: true, forFlat: false },
  { value: 'wireframe', label: 'Каркас', forHouse: true, forFlat: false },
  { value: 'brickMonolith', label: 'Кирпич-монолит', forHouse: false, forFlat: true },
  { value: 'panel', label: 'Панельный', forHouse: false, forFlat: true },
  { value: 'block', label: 'Блочный', forHouse: false, forFlat: true },
  { value: 'stalin', label: 'Сталинский', forHouse: false, forFlat: true }
]
export const REALTY_PURPOSE_OPTIONS = [{ value: 'living', label: 'Жилая' }]
export const CURRENCY_OPTIONS = [
  { value: 'RUB', label: 'Рубли' },
  { value: 'USD', label: 'Доллары' },
  { value: 'EUR', label: 'Евро' }
]

export const MORTGAGE_OPTIONS = [
  { value: true, label: 'Да' },
  { value: false, label: 'Нет' }
]

export const DEAL_TYPE_MORTGAGE_OPTIONS = [
  { value: 'free', label: 'Свободная продажа' },
  { value: 'alternative', label: 'Альтернатива' },
  { value: 'dupt', label: 'Переуступка' }
]

export const AVITO_WALLS_TYPE_OPTIONS = [
  { value: 'brick', label: 'Кирпич' },
  { value: 'timber', label: 'Брус' },
  { value: 'wood', label: 'Бревно' },
  { value: 'gas_block', label: 'Газоблоки' },
  { value: 'metall', label: 'Металл' },
  { value: 'foam_block', label: 'Пеноблоки' },
  { value: 'sandwhich_panel', label: 'Сэндвич-панели' },
  { value: 'concrete_panel', label: 'Ж/б панели' },
  { value: 'experimental', label: 'Экспериментальные материалы' }
]

export const FACING_TYPE_OPTIONS = [
  { value: 'full', label: 'С отделкой' },
  { value: 'fine', label: 'White box' },
  { value: 'need', label: 'Без отделки' }
]

export const PLOT_TYPE_OPTIONS = [
  { value: 'individual', label: 'ИЖС' },
  { value: 'household', label: 'ЛПХ' },
  { value: 'dacha', label: 'ДНП' },
  { value: 'gardening', label: 'Садоводство' },
  { value: 'farm', label: 'Ферм. хоз-во' },
  { value: 'industry', label: 'Промназначение' },
  { value: 'invest', label: 'Инвестпроект' }
]

export const SALE_SOURCE_OPTIONS = [
  { label: 'C NMH', value: 'with_nmh' },
  { label: 'Без NMH', value: 'without_nmh' },
  { label: 'Не указано', value: '' }
]

export const CIAN_PLACEMENTS_TYPE_OPTIONS = [
  { value: 'free', label: 'Бесплатное' },
  { value: 'paid', label: 'Платное' },
  { value: 'premium', label: 'Премиум' },
  { value: 'top3', label: 'ТОП' },
  { value: 'highlight', label: 'Выделение цветом' }
]

export const STATUS_OPTIONS = [
  { value: 'without', label: 'Без статуса' },
  { value: 'sold', label: 'Продан' },
  { value: 'reserved', label: 'Резерв' },
  { value: 'withdrawn', label: 'Снят с продажи' },
  { value: 'exclusive', label: 'Эксклюзив' },
  { value: 'building', label: 'Строительство' },
  { value: 'partner', label: 'Партнерский' }
]

export const DEAL_TYPE_OPTIONS = [
  { value: DEAL_TYPE_SALE, label: 'Продажа' },
  { value: DEAL_TYPE_RENT, label: 'Аренда' }
]

export const ADVERT_STATUS_WITHOUT = { text: 'Без статуса', color: DARK_GRAY, value: 'without' }
export const ADVERT_STATUS_SOLD = { text: 'Продан', color: DARK_GRAY, value: 'sold' }
export const ADVERT_STATUS_RESERVED = { text: 'Резерв', color: DARK_GRAY, value: 'reserved' }
export const ADVERT_STATUS_WITHDROWN = { text: 'Снят с продажи', color: DARK_GRAY, value: 'withdrawn' }
export const ADVERT_STATUS_EXCLUSIVE = { text: 'Эксклюзив', color: DARK_GRAY, value: 'exclusive' }
export const ADVERT_STATUS_BUILDING = { text: 'Строительство', color: DARK_GRAY, value: 'building' }
export const ADVERT_STATUS_PARTNER = { text: 'Партнерский', color: DARK_GRAY, value: 'partner' }

export const ADVERT_STATUSES_GROUP = {
  without: ADVERT_STATUS_WITHOUT,
  sold: ADVERT_STATUS_SOLD,
  reserved: ADVERT_STATUS_RESERVED,
  withdrawn: ADVERT_STATUS_WITHDROWN,
  exclusive: ADVERT_STATUS_EXCLUSIVE,
  building: ADVERT_STATUS_BUILDING,
  partner: ADVERT_STATUS_PARTNER
}

export const HIGHWAY_OPTIONS = [
  { value: 'kaluzhskoe', label: 'Калужское' },
  { value: 'kievskoe', label: 'Киевсоке' },
  { value: 'minskoe', label: 'Минское' },
  { value: 'dmitrovskoe', label: 'Дмитровское' },
  { value: 'novorizhskoe', label: 'Новорижское' },
  { value: 'rublevo-uspenskoe', label: 'Рублево-Успенское' }
]
export const RENOVATION_TYPE_OPTIONS = [
  { value: 'cosmetic', label: 'Косметический' },
  { value: 'designer', label: 'Дизайнерский' },
  { value: 'euro', label: 'Евроремонт' },
  { value: 'no_renovation', label: 'Без ремонта' }
]
export const PARKING_TYPE_OPTIONS = [
  { value: 'ground', label: 'Наземная' },
  { value: 'multilayer', label: 'Многоуровневая' },
  { value: 'underground', label: 'Подземная' }
]
export const COMMUNAL_PAYMENTS_TYPE_OPTIONS = [
  { value: true, label: 'Включены' },
  { value: false, label: 'Нет' }
]
export const COUNTERS_TYPE_OPTIONS = [
  { value: true, label: 'Включены' },
  { value: false, label: 'Нет' }
]
export const RENT_DURATION_TYPE_OPTIONS = [
  { value: 'long', label: 'Длительно' },
  { value: 'multipleMonths', label: 'Несколько месяцев' }
]
export const ROOM_COUNT_OPTIONS = [
  { value: null, label: 'Не выбрано' },
  { value: '1', label: '1' },
  { value: '2', label: '2' },
  { value: '3', label: '3' },
  { value: '4', label: '4' },
  { value: '5', label: '5' },
  { value: '6', label: '6' },
  { value: 'seven_or_more', label: '7+' },
  { value: 'free', label: 'Свободная планировка' },
  { value: 'studio', label: 'Студия' }
]
export const PREPAYMENT_TYPE_OPTIONS = [
  { value: null, label: 'Не выбрано' },
  { value: 1, label: '1 месяц' },
  { value: 2, label: '2 месяца' },
  { value: 3, label: '3 месяца' },
  { value: 4, label: '4 месяца' },
  { value: 5, label: '5 месяцев' },
  { value: 6, label: '6 месяцев' },
  { value: 7, label: '7 месяцев' },
  { value: 8, label: '8 месяцев' },
  { value: 9, label: '9 месяцев' },
  { value: 10, label: '10 месяцев' },
  { value: 11, label: '11 месяцев' },
  { value: 12, label: '1 год' }
]
export const FIELD_TITLES = {
  saleSource: 'КЕМ ПРОДАН',
  soldoutAt: 'ДАТА ПРОДАЖИ',
  withdrawnAt: 'СНЯТ С ПРОДАЖИ',
  agent: 'АГЕНТ',
  name: 'НАЗВАНИЕ ДЛЯ САЙТА',
  slug: 'Ч.П.У.',
  description: 'ОПИСАНИЕ',
  status: 'СТАТУС',
  category: 'ТИП',
  settlement: 'НАСЕЛЁННЫЙ ПУНКТ',
  villageName: 'НАЗВАНИЕ КОТТЕДЖНОГО ПОСЁЛКА',
  villageCianId: 'ID КОТТЕДЖНОГО ПОСЁЛКА ДЛЯ ЦИАН',
  village: 'КОТТЕДЖНЫЙ ПОСЁЛОК',
  price: 'СТОИМОСТЬ',
  youtubeUrl: 'ССЫЛКА YOUTUBE',
  buildYear: 'ГОД ПОСТРОЙКИ',
  area: 'ПЛОЩАДЬ ОБЪЕКТА (кв. м.)',
  livingArea: 'ЖИЛАЯ ПЛОЩАДЬ ОБЪЕКТА (кв. м.)',
  kitchenArea: 'ПЛОЩАДЬ КУХНИ (кв. м.)',
  plottage: 'ПЛОЩАДЬ УЧАСТКА (сот.)',
  bedroomsCount: 'КОЛ-ВО СПАЛЕН',
  roomCount: 'КОЛ-ВО КОМНАТ',
  prepaymentMonths: 'ПРЕДОПЛАТА',
  deposit: 'ЗАЛОГ СОБСТВЕННИКУ',
  wcCount: 'КОЛ-ВО САН. УЗЛОВ',
  combinedWcCount: 'СОВМЕЩЕННЫЕ С/У',
  renovationType: 'ТИП РЕМОНТА',
  floor: 'ЭТАЖ',
  countersIncluded: 'СЧЕТЧИКИ',
  communalPaymentsIncluded: 'КОММУНАЛЬНЫЕ ПЛАТЕЖИ',
  separateWcCount: 'РАЗДЕЛЬНЫЕ С/У',
  loggiaCount: 'ЛОДЖИИ',
  balconiesCount: 'БАЛКОНЫ',
  parkingLot: 'ПАРКОВКА',
  elevatorCount: 'ПАССАЖИРСКИЕ ЛИФТЫ',
  serviceLiftCount: 'ГРУЗОВЫЕ ЛИФТЫ',
  windowsOutdoor: 'ОКНА НА УЛИЦУ',
  windowsCourtyard: 'ОКНА ВО ДВОР',
  floors: 'КОЛ-ВО ЭТАЖЕЙ В ДОМЕ',
  rentDuration: 'СРОК АРЕНДЫ',
  realtyPurpose: 'КАТЕГОРИЯ НЕДВИЖИМОСТИ',
  housingComplexName: 'НАЗВАНИЕ ЖК',
  housingComplexId: 'ID ЖК ДЛЯ ЦИАН',
  layers: 'КОЛ-ВО УРОВНЕЙ В ДОМЕ',
  houseType: 'ТИП ДОМА',
  facingType: 'ТИП ОТДЕЛКИ',
  plotType: 'ТИП УЧАСТКА',
  avitoWallsType: 'ТИП ДОМА (для авито)',
  isGas: 'ГАЗ',
  isElectricity: 'ЭЛЕКТРИЧЕСТВО',
  isSewerage: 'КАНАЛИЗАЦИЯ',
  isWater: 'ВОДОСНАБЖЕНИЕ',
  highway: 'НАЗВАНИЕ ШОССЕ',
  distanceFromMkad: 'КМ. ОТ МКАД',
  isPublished: 'ОПУБЛИКОВАН',
  isSpecial: 'ПОКАЗЫВАТЬ НА ГЛАВНОЙ',
  isYandex: 'ЯНДЕКС',
  isCian: 'ЦИАН',
  isFacebook: 'ФЕЙСБУК',
  isAvito: 'АВИТО',
  publishTerms: 'ТИП РАЗМЕЩЕНИЯ ЦИАН',
  latitude: 'ШИРОТА',
  longitude: 'ДОЛГОТА',
  address: 'АДРЕС',
  cianUrl: 'ССЫЛКА НА ЦИАН',
  yandexUrl: 'ССЫЛКА НА ЯНДЕКС',
  auxiliaryCurrency: 'ВАЛЮТА',
  landCadastralNumber: 'КАДАСТРОВЫЙ НОМЕР УЧАСТКА',
  houseCadastralNumber: 'КАДАСТРОВЫЙ НОМЕР ДОМА',
  apartmentCadastralNumber: 'КАДАСТРОВЫЙ НОМЕР КВАРТИРЫ',
  mortgage: 'ВОЗМОЖНА ИПОТЕКА',
  dealType: 'ТИП СДЕЛКИ',
  xmlYandex: 'ЯНДЕКС.НЕДВИЖИМОСТЬ',
  xmlCian: 'ЦИАН',
  xmlFacebook: 'ФЕЙСБУК',
  xmlAvito: 'АВИТО',
  xmlYandexDirect: 'ЯНДЕКС.ДИРЕКТ',
  xmlDomClick: 'ДОМКЛИК',
  seoTitle: 'СЕО ЗАГОЛОВОК',
  seoDescription: 'СЕО ОПИСАНИЕ',
  seoKeywords: 'КЛЮЧЕВЫЕ СЛОВА',

  tags: 'ТЭГИ'
}

export const LAND_PLOTS = 'Участки'
export const HOMES = 'Дома'
export const TOWNHOUSES = 'Таунхаусы'
export const APARTMENTS = 'Апартаменты'
export const FLATS = 'Квартира'
export const PENTHOUSES = 'Пентхаус'

export const HOUSES_GROUP = [HOMES, TOWNHOUSES]
export const APARTMENTS_GROUP = [FLATS, PENTHOUSES, APARTMENTS]
export const LAND_PLOTS_GROUP = [LAND_PLOTS]

export const STATUS_SOLD = 'sold'

export const SORT_OPTIONS_MAP = [
  { label: 'Сначала новые', value: '-id' },
  { label: 'Сначала старые', value: 'id' },
  { label: 'По убыванию цены', value: '-price' },
  { label: 'По возрастанию цены', value: 'price' },
  { label: 'По количеству показов', value: '-shows_count' },
  { label: 'По количеству лидов', value: '-calls_count' }
]

export const UNIC_FIELDS_MAP = {
  category: {
    apartments: [
      'apartmentCadastralNumber',
      'livingArea',
      'kitchenArea',
      'roomCount',
      'loggiaCount',
      'balconiesCount',
      'separateWcCount',
      'combinedWcCount',
      'renovationType',
      'floor',
      'housingComplexName',
      'housingComplexId',
      'parkingLot',
      'elevatorCount',
      'serviceLiftCount',
      'windowsOutdoor',
      'windowsCourtyard'
    ],
    houses: [
      'villageCianId',
      'villageName',
      'settlement',
      'village',
      'plottage',
      'layers',
      'wcCount',
      'facingType',
      'plotType',
      'isGas',
      'isElectricity',
      'isSewerage',
      'isWater',
      'layers',
      'avitoWallsType',
      'houseCadastralNumber',
      'landCadastralNumber'
    ]
  },
  dealType: {
    [DEAL_TYPE_SALE]: ['mortgage'],
    [DEAL_TYPE_RENT]: ['rentDuration', 'communalPaymentsIncluded', 'countersIncluded', 'deposit', 'prepaymentMonths']
  }
}

export const ADVERTS_FIELDS_BY_CATEGORIES = {
  basic: [
    'isPublished',
    'isSpecial',
    'xmlYandex',
    'xmlCian',
    'xmlFacebook',
    'xmlAvito',
    'xmlYandexDirect',
    'xmlDomClick',
    'publishTerms',
    'cianAuctionBid',
    'status',
    'soldoutAt',
    'saleSource'
  ],
  about: [
    'houseType',
    'landCadastralNumber',
    'houseCadastralNumber',
    'apartmentCadastralNumber',
    'mortgage',
    'category',
    'avitoWallsType',
    'facingType',
    'plotType',
    'dealType',
    'isElectricity',
    'isGas',
    'isSewerage',
    'isWater',
    'prepaymentMonths',
    'deposit',
    'wcCount',
    'realtyPurpose',
    'separateWcCount',
    'combinedWcCount',
    'renovationType',
    'loggiaCount',
    'balconiesCount',
    'bedroomsCount',
    'roomCount',
    'floors',
    'countersIncluded',
    'communalPaymentsIncluded',
    'rentDuration',
    'housingComplexName',
    'housingComplexId',
    'parkingLot',
    'floor',
    'layers',
    'youtubeUrl',
    'price',
    'auxiliaryCurrency',
    'price',
    'auxiliaryPrice',
    'buildYear',
    'area',
    'plottage',
    'tags'
  ],
  description: ['description', 'name'],
  seo: ['seoTitle', 'seoDescription'],
  placement: ['latitude', 'longitude', 'address', 'settlement', 'village', 'villageName', 'villageCianId'],
  photos: ['photos', 'advertMediaOrders', 'deletedPhotos', 'draftMediaOrders', 'draftDeletedPhotos'],
  agents: ['agent'],
  links: ['cianUrl', 'yandexUrl', 'domClickUrl']
}
