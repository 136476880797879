// STORE TYPES
export const MODULE_VILLAGES = 'villages'

// ACTION TYPES
export const RESET_STATE = 'resetState'
export const FETCH_VILLAGE = 'fetchVillage'
export const UPDATE_VILLAGE = 'updateVillage'
export const CREATE_VILLAGE = 'createVillage'

// MUTATION TYPES
export const SET_VILLAGE = 'setVillage'
export const PATCH_SECTION_INFO = 'patchSectionInfo'
export const RESET_STATE_MUTATION = 'resetStateMutation'

// STATE TYPES
export const BASIC_INFO = 'basicInfo'
export const DESCRIPTION_INFO = 'descriptionInfo'
export const SEO_INFO = 'seoInfo'
export const PLACEMENT_INFO = 'placementInfo'
export const PHOTOS = 'photosInfo'
export const FILES = 'filesInfo'
export const RESPONSIBLE_AGENTS = 'responsibleAgent'

// GETTER TYPES
export const FULL_VILLAGE_MODEL = 'fullVillageModel'
