import { AnalyticsBrowser } from '@segment/analytics-next'

import { loadStorageItem } from '@/services/storage'

const isNmh = process.env.VUE_APP_IS_NMH === 'true'

const GROUP_ID_KEY = 'ajs_group_id'

export const analytics = AnalyticsBrowser.load(
  {
    writeKey: isNmh ? 'Fy9hOxmW2EiB3D5Ded62V8XyWpmZqaTW' : 'JvrLldBBq9KuHcIOkFkG4foM1dBdruhO'
  },
  () => {}
)

export const analyticsEvents = {
  inviteSent({ inviteeEmail, inviteeFirstName, inviteeLastName, inviteeRole }) {
    return analytics.track(
      'Invite Sent',
      {
        invitee_email: inviteeEmail,
        invitee_first_name: inviteeFirstName,
        invitee_last_name: inviteeLastName,
        invitee_role: inviteeRole
      },
      {
        context: {
          groupId: loadStorageItem(GROUP_ID_KEY)
        }
      }
    )
  },
  signedIn({ loggedInAt, loginMethod }) {
    return analytics.track(
      'Signed In',
      { logged_in_at: loggedInAt, login_method: loginMethod },
      { context: { groupId: loadStorageItem(GROUP_ID_KEY) } }
    )
  },
  signedOut({ logoutPlatform, loggedOutAt }) {
    return analytics.track('Signed Out', { logout_platform: logoutPlatform, logged_out_at: loggedOutAt }).then(() => {
      return analytics.reset()
    })
  },

  tourAdded({ tourStatus, pageCategory, commentAdded, actOfViewingAttached }) {
    return analytics.track('Tour Added', {
      tour_status: tourStatus,
      page_category: pageCategory,
      comment_added: commentAdded,
      act_of_viewing_attached: actOfViewingAttached
    })
  },

  leadAdded({ leadType, commentAdded, leadStatus }) {
    return analytics.track('Lead  Added', {
      lead_type: leadType,
      comment_added: commentAdded,
      lead_status: leadStatus
    })
  },

  propertyOwnerAdded({ name, phone, email }) {
    return analytics.track('Property Owner Added', {
      property_owner_name: name,
      property_owner_phone_number: phone,
      property_owner_email: email || ''
    })
  },

  customerAdded({ name, phone, email }) {
    return analytics.track('Customer Added', {
      customer_name: name,
      customer_phone_number: phone,
      customer_email: email || ''
    })
  },

  commentAdded({ fileAttached, pageCategory }) {
    return analytics.track('Comment Added', {
      file_attached: fileAttached,
      page_category: pageCategory
    })
  },

  outCallAdded({ outCallStatus, commentAdded, pageCategory }) {
    return analytics.track('Out Call Added', {
      out_call_status: outCallStatus,
      comment_added: commentAdded,
      page_category: pageCategory
    })
  },

  customerProfileChanged({ informationChanged }) {
    return analytics.track('Customer Profile Changed', {
      information_changed: informationChanged
    })
  },

  customerProfileUpdated({ postAdded }) {
    return analytics.track('Customer Profile Updated', {
      post_added: postAdded
    })
  },

  propertyOwnerProfileChanged({ informationChanged }) {
    return analytics.track('Property Owner Profile Changed', {
      information_changed: informationChanged || undefined
    })
  },

  propertyOwnerProfileUpdated({ numberOfPropertyAdded, numberOfDocumentsUploaded, postAdded }) {
    return analytics.track('Property Owner Profile Updated', {
      number_of_property_added: numberOfPropertyAdded || undefined,
      number_of_documents_uploaded: numberOfDocumentsUploaded || undefined,
      post_added: postAdded || undefined
    })
  },

  reminderChanged({ reminderType, reminderStatus, timeChanged, dateChanged, commentChanged }) {
    return analytics.track('Reminder Changed', {
      reminder_type: reminderType,
      reminder_status: reminderStatus,
      time_changed: timeChanged,
      date_changed: dateChanged,
      comment_changed: commentChanged
    })
  },

  propertyAdded({ propertyCategory, addedSuccessfully, propertyDraftSaved, propertyDealType, propertyType }) {
    return analytics.track('Listing Added', {
      property_category: propertyCategory,
      added_successfully: addedSuccessfully,
      property_draft_saved: propertyDraftSaved,
      property_deal_type: propertyDealType,
      property_type: propertyType
    })
  },

  propertyChanged({ basic, about, description, seo, placement, photos, agents, links }) {
    return analytics.track('Listing Changed', {
      status_and_feeds_changed: basic,
      property_info_changed: about,
      location_changed: placement,
      photos_changed: photos,
      agent_changed: agents,
      marketplace_links_changed: links,
      description_changed: description,
      seo_changed: seo
    })
  },

  draftPropertyAdded({ propertyCategory, propertyDealType, propertyType }) {
    return analytics.track('Draft Listing Added', {
      property_category: propertyCategory,
      property_deal_type: propertyDealType,
      property_type: propertyType
    })
  },

  draftPropertySentForApproval({ propertyCategory, propertyDealType, propertyType }) {
    return analytics.track('Draft Listing Sent For Approval', {
      property_category: propertyCategory,
      property_deal_type: propertyDealType,
      property_type: propertyType
    })
  },

  draftPropertyChanged({ propertyCategory, propertyDealType, propertyType }) {
    return analytics.track('Draft Listing Changed', {
      property_category: propertyCategory,
      property_deal_type: propertyDealType,
      property_type: propertyType
    })
  },

  notificationRead({ notificationCategory }) {
    return analytics.track('Notification Read', {
      notification_category: notificationCategory
    })
  },

  notificationReplied({ notificationCategory, notficationApproved }) {
    return analytics.track('Notification Replied', {
      notification_category: notificationCategory,
      notfication_approved: notficationApproved
    })
  }
}
