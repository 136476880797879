<template>
  <div class="v-alert-group">
    <v-alert :alert="alert" @closed="shiftAlert" />
  </div>
</template>

<script>
import { alerts, alertService } from '@/services/alert'
import VAlert from './VAlert.vue'

export default {
  name: 'VAlertGroup',
  components: { VAlert },
  computed: {
    alert() {
      return alerts.list[0]
    },
    type() {
      return this.alert?.type
    },
    text() {
      return this.alert?.text || ''
    }
  },
  methods: {
    shiftAlert() {
      alertService.shift()
    }
  }
}
</script>
