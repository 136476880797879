import BaseHttpService from './base'

class GeoService extends BaseHttpService {
  getSettlements() {
    return this.client.get('/geo/settlements/')
  }

  getAreas() {
    return this.client.get('/geo/areas/')
  }
}

export default GeoService
