import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@/store'
import middlewarePipeline from '@/router/middlewarePipeline'
import { collectMatchedMiddleware } from '@/utils/router'
import { analytics } from '@/services/segment'
import { isFunction, isString } from '@/utils/common'
import routes from './routes'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

// Решение взято с https://github.com/vuejs/vue-router/issues/2881
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location, onResolve, onReject) {
  if (onResolve || onReject) return originalPush.call(this, location, onResolve, onReject)
  return originalPush.call(this, location).catch(err => {
    if (VueRouter.isNavigationFailure(err)) {
      // resolve err
      return err
    }
    // rethrow error
    return Promise.reject(err)
  })
}

// разрешение переходов
router.beforeEach((to, from, next) => {
  const middleware = collectMatchedMiddleware(to.matched)

  const firstMiddleware = middleware[0]
  if (!firstMiddleware) {
    next()
    return
  }

  const context = { to, from, next, store }
  middlewarePipeline(context, middleware)()
})

router.afterEach((to, from) => {
  if (to.name !== from.name) {
    const { segmentAnalytics, seoTitle } = to.meta
    if (seoTitle) {
      let localSeoTitle = ''
      if (isString(seoTitle)) {
        localSeoTitle = seoTitle
      } else if (isFunction(seoTitle)) {
        localSeoTitle = seoTitle(to)
      }
      document.title = localSeoTitle

      if (segmentAnalytics && !segmentAnalytics.isDynamic) {
        const { name } = segmentAnalytics
        analytics.page(name, { title: localSeoTitle })
      }
    }
  }
})

export default router
