import { CUSTOMER_STATUS_ARCHIVE } from '@/constants/statuses/customers'
import { getStringCapitalize, toSnakeCaseObject } from '@/utils/common'
// import { DRAFT_STATUS_ACCEPTED } from '@/constants/draftStatuses'
import { DRAFT_STATUS_ACCEPTED } from '@/constants/draftStatuses'
import { deleteEverythingExceptNumbers, formatClientObject } from '@/utils/formatters'
import { analyticsEvents } from '@/services/segment'
import { COMMENT, FILE, OUTGOING_CALL } from '@/constants/ownerInteractionHistory'
import BaseHttpService from './base'

class CustomersService extends BaseHttpService {
  select(query) {
    let params = {}
    if (query) {
      params = /\d/.test(query[0]) ? { phone: deleteEverythingExceptNumbers(query) } : { name: query }
    }
    return this.client
      .get('/customers/select/', { params })
      .then(({ results }) => results.map(el => formatClientObject(el)))
  }

  getList({
    limit,
    offset,
    agentId,
    query,
    phone,
    categoryId,
    status,
    priceLte,
    priceGte,
    orderBy,
    workflowStatus,
    dateFrom,
    dateTo,
    lastReminderFrom,
    lastReminderTo,
    cancelToken
  }) {
    const params = toSnakeCaseObject({
      limit,
      offset,
      agent: agentId,
      name: query,
      advertCategory: categoryId,
      priceLte,
      priceGte,
      dateFrom,
      dateTo,
      lastReminderFrom,
      lastReminderTo,
      status: workflowStatus,
      orderBy,
      phone
    })
    if (status === CUSTOMER_STATUS_ARCHIVE) {
      return this.client.get('/customers/archived/', { params, cancelToken })
    }
    return this.client.get('/customers/', {
      params,
      cancelToken
    })
  }

  getCustomerById(id) {
    return this.client.get(`/customers/${id}/`)
  }

  getCustomerDraft(versionId) {
    return this.client.get(`/customers/versions/${versionId}/`)
  }

  getCustomerDraftChanges(versionId) {
    return this.client.get(`/customers/versions/${versionId}/get_changes/`)
  }

  update(id, params, isEditProfile = false, changedValues) {
    const { name, email, phone, agent, status, callSource } = params

    const payload = isEditProfile
      ? {
          name: name || undefined,
          email: email || undefined,
          phone: phone || undefined,
          customerProfile: {
            agent: agent?.id || undefined,
            status: status?.id || undefined,
            callSource: callSource?.id || undefined
          }
        }
      : params

    return this.client.patch(`/customers/${id}/`, payload).then(result => {
      if (isEditProfile) {
        analyticsEvents.customerProfileChanged({ informationChanged: changedValues })
      }
      return result
    })
  }

  bulkUpdate(ids, { status, callSource, agent }) {
    const payload = {
      customerIds: ids,
      updatedCustomerData: {
        customerProfile: {
          callSource: callSource?.id || undefined,
          agent: agent?.id || undefined,
          status: status?.id || undefined
        }
      }
    }
    return this.client.patch(`/customers/bulk-update/`, payload)
  }

  bulkArchiveCustomers(ids) {
    const payload = {
      customerIds: ids,
      isArchived: true
    }
    return this.client.patch(`/customers/archive/`, payload)
  }

  bulkActivateCustomers(ids) {
    const payload = {
      customerIds: ids,
      isArchived: false
    }
    return this.client.patch(`/customers/archive/`, payload)
  }

  getCustomerStatusesMap() {
    return this.client.get(`/customers/choices/status/`)
  }

  getCallSourcesMap() {
    return this.client.get(`/customers/choices/call_source/`)
  }

  createHistory(id, data) {
    return this.client.post(`/customers/${id}/history/`, data).then(() => {
      if ([COMMENT, FILE].includes(data.type)) {
        analyticsEvents.customerProfileUpdated({
          postAdded: getStringCapitalize(data.type === COMMENT ? COMMENT : FILE)
        })
        analyticsEvents.commentAdded({ fileAttached: !!data.files?.length, pageCategory: 'Customer Profile' })
      }
      // todo: возвращать с бэка статус
      if (data.type === OUTGOING_CALL) {
        analyticsEvents.customerProfileUpdated({
          postAdded: 'Out Call'
        })
        analyticsEvents.outCallAdded({ outCallStatus: '', commentAdded: !!data.text, pageCategory: 'Customer Profile' })
      }
    })
  }

  getHistory(id) {
    return this.client.get(`/customers/${id}/history/`)
  }

  updateHistory(customerId, id, { type, text, reminderDatetime, files, deletedFiles, changedFields }) {
    const payload = { type, text, reminderDatetime, files, deletedFiles }
    return this.client.patch(`/customers/${customerId}/history/${id}/`, payload).then(() => {
      if (type === OUTGOING_CALL) {
        analyticsEvents.reminderChanged({
          reminderType: 'Out Call',
          timeChanged: changedFields.time,
          dateChanged: changedFields.date,
          commentChanged: changedFields.comment
        })
      }
    })
  }

  deleteHistory(customerId, id) {
    return this.client.delete(`/customers/${customerId}/history/${id}/`)
  }

  approveHistoryDraft(id, customerId, draftStatus) {
    if (draftStatus === DRAFT_STATUS_ACCEPTED) {
      return this.client.post(`/customers/${customerId}/history/versions/${id}/apply_revision/`)
    }
    return this.client.post(`/customers/${customerId}/history/versions/${id}/reject_revision/`)
  }

  approveDraft(id, { initialNotificationId, draftStatus }) {
    if (draftStatus === DRAFT_STATUS_ACCEPTED) {
      return this.client.post(`/customers/versions/${id}/apply_revision/`, {
        initialNotificationId
      })
    }
    return this.client.post(`/customers/versions/${id}/reject_revision/`, {
      initialNotificationId
    })
  }
}
export default CustomersService
