import { LOGIN_ROUTE_NAME } from '@/constants/routes'
import { LOGOUT } from '@/store/common/actions.types'
import { analyticsEvents } from '@/services/segment'
import { getUnixTimestampDate } from '@/utils/common'

export default function logout({ next, store }) {
  // TODO: Подумать как вынести в мапу
  store
    .dispatch(LOGOUT)
    .then(() => {
      let logoutPlatform = 'Web'
      if (/iPhone|iPad/i.test(navigator.userAgent)) logoutPlatform = 'iOS'
      else if (/Android/i.test(navigator.userAgent)) logoutPlatform = 'Android'
      return analyticsEvents.signedOut({ logoutPlatform, loggedOutAt: getUnixTimestampDate() })
    })
    .then(() => next({ name: LOGIN_ROUTE_NAME }))
    .catch(() => window.location.reload())
}
