// STORE TYPES
export const MODULE_REALTY = 'realty'

// ACTION TYPES
export const RESET_STATE = 'resetState'
export const FETCH_REALTY_BY_ID = 'fetchRealtyById'
export const UPDATE_REALTY = 'updateRealty'
export const CREATE_REALTY = 'createRealty'
export const UPDATE_UPLOADING_PHOTOS_STATUS = 'updateUploadingPhotosStatus'

// MUTATION TYPES
export const SET_REALTY = 'setRealty'
export const PATCH_SECTION_INFO = 'patchSectionInfo'
export const RESET_STATE_MUTATION = 'resetStateMutation'
export const SET_UPLOADING_PHOTOS_STATUS = 'setUploadingPhotosStatus'

// STATE TYPES
export const BASIC_INFO = 'basicInfo'
export const ABOUT_OBJECT = 'aboutObject'
export const DESCRIPTION_INFO = 'descriptionInfo'
export const SEO_INFO = 'seoInfo'
export const PLACEMENT_INFO = 'placementInfo'
export const PHOTOS = 'photosInfo'
export const LINKS = 'links'
export const RESPONSIBLE_AGENT = 'responsibleAgent'

// GETTER TYPES
export const FULL_REALTY_MODEL = 'fullRealtyModel'
export const SALE_SOURCES = 'saleSources'
