import { ENTRY_ROUTE_NAME_FOR_STAFF } from '@/constants/routes'
import { MODULE_SESSION } from '@/store/modules/session/session.types'

export default function redirectIfNotNmh({ next, store }) {
  const isNmh = store.getters[`${MODULE_SESSION}/isNmh`]
  if (isNmh) {
    return next()
  }
  return next({ name: ENTRY_ROUTE_NAME_FOR_STAFF })
}
