import conformToMask from 'text-mask-core/src/conformToMask'
import dateFormat from 'date-format'
import { PHONE_MASK, TEXT_MASK_CONFIG_DEFAULT } from '@/constants/textMask'

export function formatAdvertObject(advert) {
  return { ...advert, name: `${advert.id} - ${advert.name}` }
}

export function formatAgentObject(agent) {
  return { ...agent, id: agent.profileId }
}
export function formatPhone(phone) {
  const phoneMask = conformToMask(phone, PHONE_MASK, TEXT_MASK_CONFIG_DEFAULT)
  return phoneMask.conformedValue
}
export function formatAdvertName(advert, flag) {
  if (flag) {
    return null
  }
  const { id, name, roundedPrice } = advert
  const newNameArray = []
  if (id) {
    newNameArray.push(id)
  }
  if (name) {
    newNameArray.push(name)
  }
  if (roundedPrice) {
    newNameArray.push(roundedPrice)
  }
  if (newNameArray.length) {
    return newNameArray.join(' - ')
  }
  return null
}

export function formatAccountName(data, flag) {
  if (flag) {
    return null
  }
  if (data?.account) {
    const { phone, name } = data?.account
    const newNameArray = []
    if (phone) {
      newNameArray.push(formatPhone(phone))
    }
    if (name) {
      newNameArray.push(name)
    }
    return newNameArray.join(' - ')
  }
  const { clientPhone, clientName } = data
  const newNameArray = []
  if (clientPhone) {
    newNameArray.push(formatPhone(clientPhone))
  }
  if (clientName) {
    newNameArray.push(clientName)
  }
  if (newNameArray.length) {
    return newNameArray.join(' - ')
  }
  return null
}

export function deleteEverythingExceptNumbers(value) {
  return value.replace(/\D/g, '')
}
export function formatClientObjectForComparison(
  { name, phone, email, agent, callSource, status, adverts },
  { isAdmin = false }
) {
  return {
    name: name || undefined,
    phone: phone || undefined,
    email: email || undefined,
    agent: isAdmin && agent?.id ? agent?.id : undefined,
    callSource: callSource?.id || undefined,
    status: status?.id || undefined,
    adverts: adverts?.map(advert => advert.id)?.sort((a, b) => a - b) || undefined
  }
}

export function getStringFromArrayByKeyValues(array, key = 'id') {
  return array.map(elem => elem[key]).join()
}

export function formatShowObjectForComparison(
  { agent, account, description, showDate, advert, showTime, showTimeHours, showTimeMinutes, photos },
  { isAdmin = false }
) {
  let time = null
  if (showTime) {
    if (showTimeHours && showTimeMinutes) {
      time = [showTimeHours, showTimeMinutes].join(':')
    } else time = showTime
  }
  return {
    account: account?.id || undefined,
    agent: isAdmin && agent?.id ? agent?.id : undefined,
    advert: advert?.id || undefined,
    description: description || undefined,
    showDate: showDate || undefined,
    showTime: time || undefined,
    photos: photos?.length || undefined
  }
}

export function formatCallObjectForComparison(
  { agent, name, phone, account, callSource, text, callDate, advert },
  { isAdmin = false }
) {
  return {
    name: name || account?.name || undefined,
    phone: phone || account?.phone || undefined,
    agent: isAdmin && agent?.id ? agent?.id : undefined,
    callSource: callSource?.id || account?.profile?.callSource || undefined,
    advert: advert?.id || undefined,
    text: text || undefined,
    callDate: callDate || undefined
  }
}
export function getTextWithLinks(text) {
  return typeof text === 'string'
    ? text?.replace(
        /((http|https):\/\/(www\.)?([\w-]{1,32}\.[\w-]{1,32})[^\s@]*)/g,
        match => `<a target='_blank' href=${match}>${match}</a>`
      ) || ''
    : text
}
export function formatMoney(amount, decimalCount = 0, thousands = ' ', decimal = ',') {
  let decimalCountParsed = Math.abs(decimalCount)
  decimalCountParsed = Number.isNaN(decimalCountParsed) ? 0 : decimalCountParsed

  const negativeSign = amount < 0 ? '-' : ''

  const amountParsed = Math.abs(Number(amount) || 0).toFixed(decimalCountParsed)
  const i = parseInt(amountParsed, 10).toString()
  const j = i.length > 3 ? i.length % 3 : 0

  return (
    negativeSign +
    (j ? i.substr(0, j) + thousands : '') +
    i.substr(j).replace(/(\d{3})(?=\d)/g, `$1${thousands}`) +
    (decimalCountParsed
      ? decimal +
        Math.abs(amountParsed - i)
          .toFixed(decimalCountParsed)
          .slice(2)
      : '')
  )
}

export function roundMoney(value) {
  let strValue = String(value ?? '-')
  if (value >= 1000000) strValue = `${Math.round(value / 10000) / 100} млн`
  else if (value > 1000) strValue = `${Math.round(value / 1000)} тыс`
  return strValue.replace('.', ',')
}

export function roundMoneyWithoutPrefix(value) {
  let numValue = 0
  if (value >= 1000000) numValue = Math.round(value / 10000) / 100
  else if (value > 1000) numValue = Math.round(value / 1000)

  return numValue
}

export function formatTime(timestamp) {
  return dateFormat('hh:mm', new Date(timestamp))
}

export function formatClientObject(client) {
  return { ...client, name: `${formatPhone(client.phone)} - ${client.name}` }
}

export const MONTHS_IN_GENITIVE_CASE = [
  'Января',
  'Февраля',
  'Марта',
  'Апреля',
  'Мая',
  'Июня',
  'Июля',
  'Августа',
  'Сентября',
  'Октября',
  'Ноября',
  'Декабря'
]
export const MONTHS = [
  'Январь',
  'Февраль',
  'Март',
  'Апрель',
  'Май',
  'Июнь',
  'Июль',
  'Август',
  'Сентябрь',
  'Октябрь',
  'Ноябрь',
  'Декабрь'
]

export function reformatDate(date) {
  const [day, monthNumber, year] = date.split('.')
  return `${year}-${monthNumber}-${day}`
}

export function formatDate(timestamp, separator = ' ', isMonthByNumber = false, isStandartFormat = false) {
  const date = new Date(timestamp)
  const year = date.getFullYear()
  let month = isMonthByNumber ? MONTHS_IN_GENITIVE_CASE[date.getMonth()] : date.getMonth() + 1
  month = String(month).padStart(2, '0')
  let day = date.getDate()
  day = String(day).padStart(2, '0')
  if (isStandartFormat) {
    return [year, month, day].join(separator)
  }
  return [day, month, year].join(separator)
}

export function formatDateTime(timestamp, separator, isMonthByNumber, isWatch = false) {
  const date = formatDate(timestamp, separator, isMonthByNumber)
  if (!isWatch) return date

  const time = formatTime(timestamp)
  return `${date} ${time}`
}

export function formatDatePreview(from, to) {
  if (from || to) {
    return `${from || '—.—.—'} - ${to || '—.—.—'}`
  }
  return null
}

export function formatObjectRealty(object) {
  const agent = {
    ...object.agent,
    name: object.agent?.name || ''
  }
  const formattedPrice = `${formatMoney(object.price)} ₽`

  return {
    ...object,
    agent,
    price: formattedPrice
  }
}
