import { enableBodyScroll, disableBodyScroll } from '@/libs/body-scroll-lock'

// eslint-disable-next-line consistent-return
const allowTouchMove = element => {
  let currentElement = element
  while (currentElement && currentElement !== document.body) {
    if (currentElement.getAttribute('body-scroll-lock-ignore') !== null) {
      return true
    }

    currentElement = currentElement.parentElement
  }
  return false
}

function getOptions(options) {
  if (typeof options === 'boolean') {
    return {
      lock: options
    }
  }
  if (typeof options === 'object') {
    return options
  }

  return {
    lock: false
  }
}
const VScrollLock = {
  inserted(el, { value }) {
    const { lock, options } = getOptions(value)

    if (lock) {
      disableBodyScroll(el, { allowTouchMove, ...options })
    }
  },
  componentUpdated(el, { value }) {
    const { lock, options = {} } = getOptions(value)

    if (lock) {
      disableBodyScroll(el, { allowTouchMove, ...options })
    } else {
      enableBodyScroll(el)
    }
  },
  unbind(el) {
    enableBodyScroll(el)
  }
}

export default VScrollLock
