import { LOGIN_ROUTE_NAME } from '@/constants/routes'
import { FETCH_USER_INFO, MODULE_SESSION } from '@/store/modules/session/session.types'
import { MODULE_NOTIFICATIONS, INIT_NOTIFICATION_FETCHING } from '@/store/modules/notifications/notifications.types'
import { MODULE_REMINDERS, INIT_REMINDER_FETCHING } from '@/store/modules/reminders/reminders.types'
import { LOGOUT } from '@/store/common/actions.types'

export default function loadProfile({ next, store }) {
  const isProfileLoaded = store.getters[`${MODULE_SESSION}/isProfileLoaded`]
  if (!isProfileLoaded) {
    return store
      .dispatch(`${MODULE_SESSION}/${FETCH_USER_INFO}`)
      .then(() => {
        const isRoleOwner = store.getters[`${MODULE_SESSION}/isRoleOwner`]

        const notificationsInitResolver = store.dispatch(`${MODULE_NOTIFICATIONS}/${INIT_NOTIFICATION_FETCHING}`)
        const remindersInitResolver = store.dispatch(`${MODULE_REMINDERS}/${INIT_REMINDER_FETCHING}`)

        return !isRoleOwner && Promise.all([notificationsInitResolver, remindersInitResolver])
      })
      .then(() => next())
      .catch(() => {
        return store.dispatch(LOGOUT).then(() => next({ name: LOGIN_ROUTE_NAME }))
      })
      .catch(() => next(false))
  }

  return next()
}
