<template>
  <div class="v-notification-group">
    <v-error-dialog
      v-if="!!errorFields.length"
      :title="title"
      :list-title="listTitle"
      :error-fields="errorFields"
      :button-text="buttonText"
      :visible="!!notification"
      @close="removeItem"
      @update:visible="removeItem"
    />
    <v-notification v-else :type="type" :text="text" :visible="!!notification" @update:visible="removeItem" />
  </div>
</template>

<script>
import { notificationService, notifications } from '@/services/notification'
import { FIELD_TITLES } from '@/constants/objectRealtyOptions'
import VErrorDialog from '@/components/common/notifications/VErrorDialog.vue'
import { getStringCapitalize } from '@/utils/common'
import VNotification from './VNotification.vue'

export default {
  name: 'VNotificationGroup',
  components: { VNotification, VErrorDialog },
  computed: {
    notification() {
      return notifications.list[0]
    },
    type() {
      return this.notification?.type
    },
    text() {
      return this.notification?.text || ''
    },
    title() {
      return this.notification?.title || ''
    },
    buttonText() {
      return this.notification?.buttonText
    },
    errorFields() {
      if (this.notification?.errorFields) {
        return Object.keys(this.notification.errorFields).reduce((fields, field) => {
          if (FIELD_TITLES[field]) {
            fields.push(FIELD_TITLES[field])
            return fields.map(item => {
              return getStringCapitalize(item).replace('Id', 'ID')
            })
          }
          return fields
        }, [])
      }
      return []
    },
    listTitle() {
      return this.notification?.listTitle
    }
  },

  methods: {
    removeItem() {
      this.notification.close()
      notificationService.shift()
    }
  }
}
</script>
